import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardActionArea,
  Skeleton,
} from "@mui/material";

const LoadingPotluckBar = () => {
    // rendering four skeleton boxes for looks
  return (
    <Box>
      <Skeleton
        variant="rounded"
        height={65}
        sx={{ borderRadius: "16px", margin: "20px 20px 0px 20px" }}
      />
      <Skeleton
        variant="rounded"
        height={65}
        sx={{ borderRadius: "16px", margin: "20px 20px 0px 20px" }}
      />
            <Skeleton
        variant="rounded"
        height={65}
        sx={{ borderRadius: "16px", margin: "20px 20px 0px 20px" }}
      />
            <Skeleton
        variant="rounded"
        height={65}
        sx={{ borderRadius: "16px", margin: "20px 20px 0px 20px" }}
      />
    </Box>
  );
};

export default LoadingPotluckBar;
