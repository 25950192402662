import React from "react";
import { Box } from "@mui/material";
import Auth from "../../utils/auth";

// import { useDispatch, useSelector } from 'react-redux';
// import { Link, useNavigate } from 'react-router-dom';
// import { useLoginMutation } from '../../slices/usersApiSlice';
// import { logout } from '../../slices/authSlice';

const Logout = () => {

  // const dispatch = useDispatch();


  Auth.logout();
  // dispatch(logout());

  return (
    <Box sx={{ height: "1000px" }}>
      <div className="medium-text" style={{ margin: "25px" }}>
        Logging you out ...
      </div>
    </Box>
  );
}

export default Logout;
