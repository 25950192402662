import React from "react";
import { Box, Skeleton } from "@mui/material";

import { useQuery } from "@apollo/client";
import { QUERY_GETALLUSERS } from "../../../utils/queries";

import UserDisplay from "../../UserDisplay";

const SuggestedFriends = () => {
  const { data, loading } = useQuery(QUERY_GETALLUSERS);
  const users = data?.getAllUsers || [];

  console.log(users);

  if (loading) {
    return (
      <Box>
        <Skeleton sx={{ margin: "10px" }} variant="rounded" />
      </Box>
    );
  }
  return (
    <Box className="basic-box" sx={{ display: {xs: "none", sm: "block" }, padding: "20px" }}>
      <Box className="medium-text" sx={{ mb: "10px" }}>
        Suggested
      </Box>
      {users.map((user, index) => (
        <Box>
          {index < 8 ? (
            <UserDisplay userId={user._id} isDisabled={false} />
          ) : (
            <Box></Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default SuggestedFriends;
