import React from "react";
import { Box, FormLabel } from "@mui/material";

const RecipeImage = ({ recipe }) => {
  return (
    <Box
      sx={{
        display: "flex",
        marginTop: "25px",
        flexDirection: "column",
      }}
    >
      <FormLabel>Image:</FormLabel>
      {recipe ? (
        <>
          {recipe.img ? (
            <div style={{ margin: "10px 0px", fontStyle: "italic" }}>
              An image has already been added, change it below
            </div>
          ) : (
            <div style={{ margin: "10px 0px", fontStyle: "italic" }}>
              No image already added
            </div>
          )}
        </>
      ) : (
        <div style={{ margin: "10px 0px", fontStyle: "italic" }}>
        Chose an image from your library below
      </div>
      )}
      <Box sx={{ marginLeft: "20px" }}>
        <input
          type="file"
          label="Image"
          id="upload-image"
          name="img"
          accept=".jpeg, .png"
        />
      </Box>
    </Box>
  );
};

export default RecipeImage;
