import React from "react";
import { Grid, Box } from "@mui/material";

// components
import LoadingTopBar from "./LoadingTopBar";
import LoadingRecipes from "./LoadingRecipes";
import LoadingMembers from "./LoadingMembers";

const LoadingComps = () => {
  // mirroring style of rendered single potluck
  return (
    <Grid container>
      <Grid item xs={12} md={9}>
        <Box>
          <LoadingTopBar />
        </Box>
        <LoadingRecipes />
      </Grid>
      <Grid item xs={12} md={3}>
        <LoadingMembers />
        <></>
      </Grid>
    </Grid>
  );
};

export default LoadingComps;
