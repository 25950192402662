import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  CardActionArea,
  CardActions,
  Box,
  Avatar,
} from "@mui/material";
import { Link } from "react-router-dom";
import "../../styles/styles.css";
import { useQuery } from "@apollo/client";
import { QUERY_GETME, QUERY_GET_POTLUCK_BY_ID } from "../../utils/queries";

// components
import ShareRecipeModal from "../ShareRecipeModal";
import EditRecipeModal from "../EditRecipeModal";
import Loading from "../Loading";
import brekky from "../../utils/assets/images/brekky.png";
import dinner2 from "../../utils/assets/images/dinner2.png";
import beverage2 from "../../utils/assets/images/beverages2.png";
import dessert3 from "../../utils/assets/images/dessert3.png";

const RecipeCardLg = ({ recipe }) => {
  const [showShareModal, setShowShareModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const { loading, data } = useQuery(QUERY_GETME);
  const userData = data?.getMe || [];

  if (loading) {
    return <Loading />;
  }

  const handleShareClick = (event) => {
    event.preventDefault();
    setShowShareModal(true);
  };

  const handleEditClick = (event) => {
    event.preventDefault();
    setShowEditModal(true);
  };

  const autoImgChoose = (type) => {
    if (type === "Beverage") {
      return beverage2;
    } else if (type === "Breakfast" || type === "Brunch") {
      return brekky;
    } else if (type === "Dessert") {
      return dessert3;
    } else if (type === "Dinner" || type === "Lunch") {
      return dinner2;
    } else {
      return dinner2;
    }
  };

  return (
    <Box>
      <Card className="recipeCard">
        <CardActionArea
          className="rec-card-area"
          component={Link}
          to={`/recipe/${recipe._id}`}
          sx={{ height: { xs: "150px", sm: "280px" } }}
        >
          <CardMedia
            component="img"
            image={recipe.img || autoImgChoose(recipe.mealType)}
            alt={recipe.name}
            sx={{
              height: { xs: "75px", sm: "150px" },
              border: { xs: "10px solid white", sm: "20px solid white" },
              boxSizing: "border-box",
            }}
          />
          <CardContent>
            <Box className="medium-text">
              {recipe.name.length < 30
                ? recipe.name
                : recipe.name.substring(0, 30) + "..."}
            </Box>
            <Box
              component="div"
              className="small-text"
              color="text.secondary"
              sx={{ marginTop: "10px", display: { xs: "none", sm: "block" } }}
            >
              {recipe.comment && recipe.comment.length > 100
                ? recipe.comment.substring(0, 100) + "..."
                : recipe.comment}
            </Box>
          </CardContent>
        </CardActionArea>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <CardActions sx={{ padding: { xs: "0px" } }}>
              <Button
                className="small-text"
                size="small"
                color="primary"
                onClick={handleShareClick}
                style={{ display: "flex", justfiyContent: "center" }}
              >
                Share
              </Button>
            </CardActions>
            {userData.privateRecipes.some((rec) => recipe._id === rec._id) ? (
              <CardActions sx={{ padding: "0px" }}>
                <Button
                  className="small-text"
                  size="small"
                  color="primary"
                  onClick={handleEditClick}
                  style={{ display: "flex", justfiyContent: "center" }}
                >
                  Edit
                </Button>
              </CardActions>
            ) : (
              null
            )}
          </Box>
          {userData.privateRecipes.some((rec) => recipe._id === rec._id) ? (
            null
          ) : (
            <Avatar
              alt="Profile picture"
              src={recipe.createdBy ? recipe.createdBy.profilePic : ""}
              sx={{ width: 20, height: 20, mr: "20px", textDecoration: "none" }}
              component={Link}
              to={`/user/${recipe.createdBy ? recipe.createdBy._id : ""}`}
            >
              <Typography sx={{ fontSize: "10px", mt: "2px" }}>
                {recipe.createdBy ? recipe.createdBy.username.charAt(0) : ""}
              </Typography>
            </Avatar>
          )}
        </Box>
      </Card>
      <ShareRecipeModal
        showShareModal={showShareModal}
        setShowShareModal={setShowShareModal}
        recipeId={recipe._id}
      />
      <EditRecipeModal
        showEditModal={showEditModal}
        setShowEditModal={setShowEditModal}
        recipe={recipe}
      />
    </Box>
  );
};

export default RecipeCardLg;
