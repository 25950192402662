// // import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';

// const baseQuery = fetchBaseQuery({ baseUrl: '' });

// export const apiSlice = createApi({
//   baseQuery,
//   tagTypes: ['User'],
//   endpoints: (builder) => ({}),
// });


import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: "http://localhost:3000" }), // change when we deploy
  tagTypes: ['User'],
  endpoints: builder => ({})
});
