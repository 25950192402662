import React from "react";
import { Box, TextField, FormLabel } from "@mui/material";

const Description = ({ recipe }) => {
  return (
    <Box sx={{ width: "95%" }}>
      <FormLabel>Description:</FormLabel>
      <TextField
        margin="normal"
        placeholder="Perfect for a quiet Friday night in!"
        name="comment"
        size="small"
        fullWidth
        multiline
        defaultValue={recipe ? recipe.comment : ""}
      />
    </Box>
  );
};

export default Description;
