import React from "react";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  FormLabel,
  Container,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const Ingredients = ({ recipe, ingredients = [], setIngredients }) => {
  // adds an ingredient
  const handleAddIng = () => {
    const newField = { id: ingredients.length + 1, value: "" };
    setIngredients([...ingredients, newField]);
  };

  // updates ingredient field
  const handleIngChange = (id, value) => {
    const updatedFields = ingredients.map((field) =>
      field.id === id ? { ...field, value } : field
    );
    setIngredients(updatedFields);
  };

  // removes ingredient when bin button clicked
  const handleIngRemove = (e, id) => {
    e.preventDefault();
    const newArray = ingredients.filter((field) => field.id !== id);

    const updatedArray = newArray.map((item, index) => ({
      ...item,
      id: index + 1,
    }));
    setIngredients(updatedArray);
  };

  // add new box if enter is pressed
  const keyIngPress = (e) => {
    if (e.keyCode === 13) {
      handleAddIng();
      e.preventDefault();
    }
  };

  return (
    <Box sx={{ marginTop: "20px", width: "95%" }}>
      <FormLabel>Ingredients:</FormLabel>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {ingredients.map((field, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormLabel>{field.id}.</FormLabel>
            <TextField
              value={field.value}
              size="small"
              onChange={(e) => handleIngChange(field.id, e.target.value)}
              onKeyDown={(e) => keyIngPress(e)}
              fullWidth
              autoFocus={ingredients.length > 1} // auto focus after enter has been pressed
              sx={{ m: "10px" }}
            />
            <IconButton
              onClick={(e) => handleIngRemove(e, field.id)}
            >
              <DeleteOutlineIcon sx={{ padding: "0px" }} />
            </IconButton>
          </Box>
        ))}
      </Box>
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          paddingLeft: "20px",
        }}
      >
        <IconButton onClick={handleAddIng}>
          <AddCircleIcon />
        </IconButton>
        <Typography>Add Ingredient</Typography>
      </Container>
    </Box>
  );
};

export default Ingredients;
