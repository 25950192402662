import React from "react";
import { Button, TextField, Box, Container } from "@mui/material";

import { useQuery, useMutation } from "@apollo/client";
import { QUERY_GETME } from "../../../utils/queries";
import { ADD_USER_DETAILS } from "../../../utils/mutations";

// components
import LandingPageHeader from "../../../components/LandingPageHeader";
import Loading from "../../../components/Loading";

const AddInfo = () => {
  const [addUserDetails] = useMutation(ADD_USER_DETAILS);
  const { loading, data } = useQuery(QUERY_GETME);
  const myData = data?.getMe || [];

  if (loading) {
    return <Loading />;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    try {
      const { data } = await addUserDetails({
        variables: {
          bio: formData.get("bio"),
          cityBorn: formData.get("cityBorn"),
          cityLive: formData.get("cityLive"),
          favCuisine: formData.get("favCuisine"),
          signatureDish: formData.get("signatureDish"),
          yob: parseFloat(formData.get("yob")),
        },
      });
      if (!data) {
        throw new Error("Something went wrong!");
      }
      window.location.replace("/signup/add-first-recipe");
    } catch (err) {
      console.error(err);
      alert("An error has been found!");
    }
  };

  return (
    <>
      <LandingPageHeader />
      <Box align="center">
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{ margin: "10px 20px 20px 20px" }}
        >
          <Box
            sx={{
              display: { sm: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: { xs: "70px", sm: "40px" },
              marginBottom: "20px",
              textAlign: "center",
            }}
          >
            <Button
              sx={{ width: "100px", display: { xs: "none", sm: "block" } }}
              disabled
            ></Button>
            <Box className="medium-text" sx={{ marginTop: "20px" }}>
              Welcome {myData.username}!
            </Box>
            <Button
              onClick={() =>
                window.location.replace("/signup/add-first-recipe")
              }
              sx={{
                height: "30px",
                width: "100px",
                display: { xs: "none", sm: "block" },
              }}
            >
              Add later
            </Button>
          </Box>
          <Box align="center">
            <Box
              className="medsmall-text"
              sx={{
                align: "center",
                margin: "15px 0px 25px 0px",
                maxWidth: "600px",
              }}
            >
              Tell us a bit more about yourself! This information will be added
              to your profile and will only be visible to your friends.
            </Box>
            <Button
              onClick={() =>
                window.location.replace("/signup/add-first-recipe")
              }
              sx={{
                height: "30px",
                width: "100px",
                display: { xs: "block", sm: "none" },
                mt: { xs: "-20px" },
                mb: { xs: "10px" },
              }}
            >
              Add later
            </Button>
          </Box>
          <Box align="center">
            <Container>
              <TextField
                margin="normal"
                fullWidth
                multiline
                rows={3}
                name="bio"
                label="Tell us about you!"
                type="bio"
                id="bio"
                sx={{ margin: "10px" }}
              />
            </Container>
            <Container
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <TextField
                margin="normal"
                fullWidth
                label="Where do you live?"
                id="cityLive"
                name="cityLive"
                sx={{ m: "10px" }}
              />
              <TextField
                margin="normal"
                fullWidth
                id="cityBorn"
                label="Where were you born?"
                name="cityBorn"
                sx={{ m: "10px" }}
              />
            </Container>
            <Container
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <TextField
                margin="normal"
                fullWidth
                name="yob"
                label="What year were you born?"
                type="yob"
                id="yob"
                sx={{ margin: "10px" }}
              />
              <TextField
                margin="normal"
                fullWidth
                name="favCuisine"
                label="Favourite cuisine"
                type="favCuisine"
                id="favCuisine"
                sx={{ margin: "10px" }}
              />
            </Container>
            <Container>
              <TextField
                margin="normal"
                fullWidth
                name="signatureDish"
                label="Signature dish"
                type="signatureDish"
                id="signatureDish"
                sx={{ margin: "10px" }}
              />
            </Container>
            <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
              Save to Profile
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddInfo;
