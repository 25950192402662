import React, { useState } from "react";
import { Box, Grid } from "@mui/material";

import Auth from "../../utils/auth";

import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  QUERY_GETME,
  QUERY_GET_USER_BY_ID,
  QUERY_GET_ALL_OUTGOING_REQUESTS,
  QUERY_GET_ALL_INCOMING_REQUESTS,
} from "../../utils/queries";

//components
import UserImage from "./UserImage";
import UserInfo from "./UserInfo";
import UserRecipes from "./UserRecipes";
import Loading from "../../components/Loading";

const UserProfile = () => {
  const { userId } = useParams();
  const { loading: meLoading, data: meData } = useQuery(QUERY_GETME);
  const me = meData?.getMe || [];

  const { loading: userLoading, data: userData } = useQuery(
    QUERY_GET_USER_BY_ID,
    { variables: { userId: userId } }
  );
  const user = userData?.getUserById || [];

  const { loading: outReqLoading, data: outReqData } = useQuery(
    QUERY_GET_ALL_OUTGOING_REQUESTS
  );
  const outReq = outReqData?.getAllMyOutgoingRequests || [];

  const { loading: inReqLoading, data: inReqData } = useQuery(
    QUERY_GET_ALL_INCOMING_REQUESTS
  );
  const inReq = inReqData?.getAllMyIncomingRequests || [];

  if (!Auth.loggedIn()) {
    window.location.assign("/");
  }

  if (meLoading || userLoading || outReqLoading || inReqLoading) {
    return <Loading />;
  }

  let isFriend = false;
  if (me.friends && me.friends.length > 0) {
    isFriend = me.friends.some((friend) => friend._id === user._id);
  }

  const iReqd = outReq.some(
    (req) => req.fromUserId._id === userId && req.status === "pending"
  );
  const theyReqd = inReq.some(
    (req) => req.fromUserId._id === userId && req.status === "pending"
  );

  var request = outReq.filter(
    (req) => req.fromUserId._id === userId && req.status === "pending"
  );
  if (theyReqd)
    request = inReq.filter(
      (req) => req.fromUserId._id === userId && req.status === "pending"
    );

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={4}>
        <Box sx={{ mb: "-8px" }}>
          <UserImage
            user={user}
            isFriend={isFriend}
            theyReqd={theyReqd}
            iReqd={iReqd}
          />
          <UserInfo user={user} isFriend={isFriend} />
        </Box>
      </Grid>
      <Grid item xs={12} sm={8}>
        <UserRecipes user={user} isFriend={isFriend} />
      </Grid>
    </Grid>
  );
};

export default UserProfile;
