import React, { useState } from "react";
import { Box } from "@mui/material";

// components
import EditInfoModal from "./Modal/EditInfoModal.js";
import MyInfo from "./components/MyInfo.js";
import Munch from "../../UserProfile/UserInfo/components/Munch";

const InfoSection = ({ loading, myData }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <Box className="vert-stack-box">
      <MyInfo loading={loading} user={myData} setShowModal={setShowModal} />
      <Munch loading={loading} user={myData} isFriend={true} />
      <EditInfoModal show={showModal} set={setShowModal} me={myData} />
    </Box>
  );
};

export default InfoSection;
