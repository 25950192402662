import React from "react";
import {
  Button,
  CssBaseline,
  TextField,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  Container,
} from "@mui/material";

import Auth from "../../utils/auth";
import { useMutation } from "@apollo/client";
import { RESET_PWD } from "../../utils/mutations";
import { useParams } from "react-router-dom";

// components
import LandingPageHeader from "../../components/LandingPageHeader";

const ResetPassword = () => {
  const { id, token } = useParams();
  const [resetPwd] = useMutation(RESET_PWD);

  const submitBtnClick = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    if (formData.get("newPwd") != formData.get("cnfNewPwd")) {
      alert("Passwords need to match!");
      return;
    }

    try {
      const { data } = resetPwd({
        variables: {
          userId: id,
          newPwd: formData.get("newPwd"),
        },
      });
      alert("Password successfully reset!")
    } catch (err) {
      console.log(err);
    }

    window.location.replace("/");
  };

  return (
    <div>
      {Auth.isTokenExpired(token) ? (
        <div>
          <LandingPageHeader />
          <div
            className="medium-text"
            style={{ marginTop: "150px", textAlign: "center" }}
          >
            This password reset link has expired
          </div>
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Link href="/" variant="body1">
              Return to login
            </Link>
          </div>
        </div>
      ) : (
        <div>
          <LandingPageHeader />
          <Container component="main" maxWidth="lg">
            <Box
              sx={{
                marginTop: 8,
              }}
            >
              <CssBaseline />
              <Box
                sx={{
                  my: 8,
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Grid item={12} component={Paper} elevation={6}>
                  <Box
                    sx={{
                      my: 5,
                      mx: 10,
                      maxWidth: "400px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <div
                        className="medium-text"
                        style={{ textAlign: "center" }}
                      >
                        Password Reset
                      </div>
                      <Box
                        component="form"
                        noValidate
                        onSubmit={(e) => submitBtnClick(e)}
                        sx={{ mt: 1, width: "400px" }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="small-text">New Password</div>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="newPwd"
                            name="newPwd"
                            type="password"
                            sx={{ maxWidth: "240px" }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="small-text">Confirm Password</div>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="cnfNewPwd"
                            name="cnfNewPwd"
                            type="password"
                            sx={{ maxWidth: "240px" }}
                          />
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 5, mb: 2, width: "200px" }}
                          >
                            Reset
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Box>
            </Box>
          </Container>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
