import React, { useState } from "react";
import { Grid, Box, Typography, Container, Button } from "@mui/material";
import Auth from "../../utils/auth";
import { useQuery } from "@apollo/client";
import { QUERY_GET_MY_POTLUCKS } from "../../utils/queries";
import PotluckBar from "./AllPotlucks/PotluckBar";
import CreatePotluckModal from "./AllPotlucks/CreatePotluck";
import Loading from "../../components/Loading";
import LoadingPotluckBar from "./AllPotlucks/LoadingPotluckBar";

const MyPotlucks = () => {
  const [showModal, setShowModal] = useState(false);
  const { loading: myPtlkLoading, data: myPtlkData } = useQuery(
    QUERY_GET_MY_POTLUCKS
  );

  // redirect if not logged in
  if (!Auth.loggedIn()) {
    window.location.replace("/");
  }

  const potlucks = myPtlkData?.getMyPotlucks.potlucks || [];

  if (myPtlkLoading) return <Loading />

  return (
    <Box>
      <Grid container>
        <Grid item xs={8}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "10px 20px 0px 20px",
            }}
          >
            <div className="box-subheader">Your Potlucks</div>
            <Button variant="contained" onClick={() => setShowModal(true)}>
              Create
            </Button>
          </Box>
          {myPtlkLoading ? (
            <LoadingPotluckBar />
          ) : (
            <div>
              {potlucks && potlucks.length === 0 ? (
                <div
                  className="large-text"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "150px",
                  }}
                >
                  You have no active Potlucks!
                </div>
              ) : (
                <>
                  <Grid>
                    {potlucks.map((potluck, index) => (
                      <PotluckBar
                        key={index}
                        id={potluck._id}
                        title={potluck.title}
                        img={potluck.img}
                        time={potluck.time}
                        place={potluck.place}
                      />
                    ))}
                  </Grid>
                </>
              )}
            </div>
          )}
        </Grid>
        <Grid item xs={4}>
          <Container>
            <Container
              className="box-empty"
              sx={{ minHeight: "400px", pb: "20px", lineHeight: "1.5" }}
            >
              <div
                className="medsmall-text"
                style={{ marginTop: "20px", fontWeight: "bold" }}
              >
                Potluck
              </div>
              <div
                className="small-text"
                style={{
                  fontStyle: "italic",
                  fontSize: "17px",
                  fontWeight: "bold",
                }}
              >
                /ˈpɒtlʌk,pɒtˈlʌk/
              </div>
              <div
                className="small-text-reg"
                style={{ marginTop: "20px", fontSize: "17px" }}
              >
                "An informal meal where guests bring different dishes that are
                then shared with the other guests."
              </div>
              <div
                className="small-text-reg"
                style={{ marginTop: "20px", fontSize: "17px" }}
              >
                In <em>TMC</em>, <strong>potlucks </strong>
                are a great way to create a group with friends or family,
                allowing each member to easily share their recipe(s) within the
                group!
              </div>
            </Container>
          </Container>
        </Grid>
      </Grid>
      <CreatePotluckModal showModal={showModal} setShowModal={setShowModal} />
    </Box>
  );
};

export default MyPotlucks;
