import React, { useState } from "react";
import {
  Button,
  CssBaseline,
  TextField,
  Link,
  Paper,
  Box,
  Grid,
  Container,
  OutlinedInput,
  InputAdornment,
  IconButton,
  InputLabel,
  FormControl,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useLocation, useNavigate } from "react-router-dom";

import Auth from "../../utils/auth";
import { useMutation } from "@apollo/client";
import { LOGIN_USER } from "../../utils/mutations";

const LoginForm = ({ recId }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [login] = useMutation(LOGIN_USER);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    try {
      // const res = await login({ email, password }).unwrap();
      // navigate('/myprofile');

      // Conduct mutation to login user
      const { data } = await login({
        variables: {
          email: formData.get("email"),
          password: formData.get("password"),
        },
      });
      if (!data) {
        throw new Error("something went wrong!");
      }
      Auth.login(data.login.token);
      Auth.signup();

      // navigate to recipe if login is for viewing shares recipe
      recId
        ? window.location.replace(`/recipe/${recId}`)
        : window.location.replace("/myprofile");

      // from ? navigate(from) : window.location.replace("/myprofile");

      // dispatch(
      //   login({
      //     username: "Test Username",
      //     email: "testEmail@email.com",
      //   })
      // );
    } catch (err) {
      console.error(err);
      alert("Incorrect username or password. Please try again!");
    }
  };

  return (
    <Grid item xs={12} sm={6} md={6} component={Paper} elevation={6} square>
      <Box className="login">
        <div className="medium-text">Log in</div>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
            <InputLabel>Email</InputLabel>
            <OutlinedInput
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
            />
          </FormControl>
          {/* <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  /> */}
          {/* <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                /> */}
          <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
              name="password"
              required
              fullWidth
              autoComplete="current-password"
            />
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/forgot-password" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
};

export default LoginForm;
