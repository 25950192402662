import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Auth from "./utils/auth";
import { useQuery } from "@apollo/client";
import { QUERY_GETME } from "./utils/queries";

// Import pages
import Navbar from "./components/Navbar";
import Recipe from "./pages/Recipe";
import Login from "./pages/Login";
import MyProfile from "./pages/MyProfile";
import Logout from "./pages/Logout";
import Signup from "./pages/Signup";
import Search from "./pages/Search";
import MyPotlucks from "./pages/MyPotlucks";
import SinglePotluck from "./pages/MyPotlucks/SinglePotluck";
import UserProfile from "./pages/UserProfile";
import AddInfo from "./pages/Signup/AddInfo";
import AddFirstRecipe from "./pages/Signup/AddFirstRecipe";
import Footer from "./components/Footer";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Temp from "./components/Temp";

const RouteHandler = () => {
  // const [user, setUser] = useState(null);
  // const getUser = async () => {
  //   try {
  //     const url = `http://localhost:5174/auth/login/success`;
  //     const { data } = await axios.get(url, { withCredentials: true });
  //     setUser(data.user._json);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // useEffect(() => {
  //   getUser();
  // }, []);

  // Get my data
  const { loading, data } = useQuery(QUERY_GETME);
  const myData = data?.getMe || [];

  return (
    <Router>
      {Auth.loggedIn() && Auth.signedUp() ? <Navbar /> : <div></div>}
      <div style={{ margin: "16px", minHeight: "100vh" }}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/myprofile" element={<MyProfile />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/search" element={<Search />} />
          <Route path="/recipe/:recId" element={<Recipe />} />
          <Route path="/mypotlucks" element={<MyPotlucks />} />
          <Route path="/mypotlucks/:potluckId" element={<SinglePotluck />} />
          <Route path="/user/:userId" element={<UserProfile />} />
          {Auth.loggedIn() && Auth.signedUp() ? (
            <Route path="/" element={<Temp myData={myData} />} />
          ) : (
            <Route path="/" element={<Login />} />
          )}
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/reset-password/:id/:token"
            element={<ResetPassword />}
          />
          <Route path="/signup" element={<Signup />} />
          <Route path="/signup/add-info" element={<AddInfo />} />
          <Route path="/signup/add-first-recipe" element={<AddFirstRecipe />} />
          <Route path="/recipe/:recId" element={<Recipe />} />
          {/* <Route path="/temp" element={<Temp />} /> */}
        </Routes>
      </div>
      {Auth.loggedIn() && Auth.signedUp() ? <Footer /> : <div></div>}
    </Router>
  );
};

export default RouteHandler;
