import styled from "styled-components";

export const Box = styled.div`
  padding: 2.5%;
  background: black;
  // position: absolute;
  bottom: 0;
  width: 95%;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin: 0 auto;
  /* background: red; */
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 60px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

export const FooterLink = styled.a`
  color: #fff;
  font-size: 18px;
  text-decoration: none;

  &:hover {
    color: #fa8661;
    transition: 200ms ease-in;
  }
`;

export const FooterLinkDisabled = styled.a`
  color: gray;
  font-size: 18px;
  text-decoration: none;
  font-family: InriaSerif-Light;
`;

export const Heading = styled.p`
  font-size: 24px;
  color: #fff;
  font-weight: bold;
  font-family: InriaSerif-Light;
`;

export const Copyright = styled.p`
font-size: 14px;
color: #fff;
text-align: center;
`;
