import React, { useState } from "react";
import {
  Button,
  CssBaseline,
  TextField,
  Link,
  Paper,
  Box,
  Grid,
  Container,
} from "@mui/material";

import { useMutation } from "@apollo/client";
import { RESET_PWD_LINK } from "../../utils/mutations";

// components
import LandingPageHeader from "../../components/LandingPageHeader";

const ForgotPassword = () => {
  const [entered, setEntered] = useState(false);
  const [resetPwdLink] = useMutation(RESET_PWD_LINK);

  const checkEmail = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    setEntered(true);

    try {
      const { data } = await resetPwdLink({
        variables: {
          email: formData.get("email"),
        },
      });
      if (!data) {
        throw new Error("something went wrong!");
      }
    } catch (err) {
      console.error(err);
      alert("Incorrect username or password. Please try again!");
    }
  };

  return (
    <div>
      <LandingPageHeader />
      <Container component="main" sx={{ maxWidth: "450px" }}>
        <Box
          sx={{
            marginTop: 8,
          }}
        >
          <CssBaseline />
          <Box>
            <Grid item={12} component={Paper} elevation={6}>
              <Box className="login">
                {!entered ? (
                  <Box>
                    <Box
                      className="medium-text"
                      sx={{ textAlign: "center", mt: "30px" }}
                    >
                      Password Reset
                    </Box>
                    <Box
                      component="form"
                      noValidate
                      onSubmit={(e) => checkEmail(e)}
                    >
                      <TextField
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoFocus
                      />
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 4 }}
                      >
                        Send Email
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <Box sx={{ m: "20px" }}>
                    <Box
                      className="medsmall-text"
                      sx={{ marginBottom: "25px" }}
                    >
                      If the email is connected to an account, a password reset
                      link will be sent.
                    </Box>
                    <Link href="/">Return to Log in</Link>
                  </Box>
                )}
              </Box>
            </Grid>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default ForgotPassword;
