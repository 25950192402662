import React from "react";
import { useGetUsersQuery } from "../../../slices/usersApiSlice";
import { Backdrop, Box, Modal, Fade, Typography, Grid } from "@mui/material";
import { useQuery } from "@apollo/client";
import { QUERY_GETALLPRIVATERECIPES, QUERY_GETME } from "../../utils/queries";

import Loading from "../Loading";
import FeedCard from "./FeedCard";
import SuggestedFriends from "./SuggestedFriends";

const Temp = ({ myData }) => {

  console.log("Hi");

  // const {
  //   data: users,
  //   isLoading,
  //   isSuccess,
  //   isError,
  //   error,
  // } = useGetUsersQuery()

  // if (isLoading) return (<Box>Loading</Box>);

  // if (isError) {
  //   console.log(error, "error")
  //   return (<Box>Error</Box>);
  // }

  // if (isSuccess) {
  //   const { ids } = users;
  //   console.log(ids, "ids")
  //   return (<Box>Success</Box>);
  // }



  const { loading: recipeLoading, data: recData } = useQuery(
    QUERY_GETALLPRIVATERECIPES
  );
  const recipeData = recData?.getAllPrivateRecipes || [];

  const { loading: meLoading, data: meData } = useQuery(QUERY_GETME);
  const me = meData?.getMe || [];

  // reverses the order
  var newRecipeArray = [];
  for (let i = 0; i < recipeData.length; i++) {
    newRecipeArray[i] = recipeData[recipeData.length - 1 - i];
  }
  console.log(newRecipeArray);

  if (recipeLoading || meLoading) return <Loading />;

  return (
    <Box>
      <Box className="basic-box" sx={{ display: "flex", alignItems: "center" }}>
        <Box className="box-header">Activity Feed</Box>
      </Box>
      <Grid container>
        <Grid item sm={8}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {newRecipeArray.map((value, index) => (
              <Box key={index}>
                {me.friends.some(
                  (friend) => friend._id === value.createdBy._id
                ) && index <= 10 ? (
                  <FeedCard recipe={value} me={me} />
                ) : (
                  <Box></Box>
                )}
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item sm={4}>
          <Box>
            <SuggestedFriends />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Temp;
