import React, { useState } from "react";
import {
  FormLabel,
  Backdrop,
  Box,
  Modal,
  Fade,
  Button,
  TextField,
  Switch,
  FormControlLabel,
  FormGroup,
  FormControl,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useMutation } from "@apollo/client";
import { CREATE_POTLUCK } from "../../../utils/mutations";

const CreatePotluckModal = ({ showModal, setShowModal }) => {
  const [checked, setChecked] = useState(false);
  const [value, setValue] = useState(null);
  const [createPotluck, { data, loading, error }] = useMutation(CREATE_POTLUCK);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 750,
    bgcolor: "white",
    borderRadius: "16px",
    boxShadow: 24,
    p: 4,
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleCheck = (event) => {
    console.log(checked);
    setChecked(!checked);
  };

  const handleCreate = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    try {
      const { data } = await createPotluck({
        variables: {
          title: formData.get("title"),
          description: formData.get("description"),
          time: value,
          place: formData.get("place"),
        },
      });
      if (!data) {
        throw new Error("Something went wrong!");
      }
      const redirectUrl = `/mypotlucks/${data.createPotluck._id}`;
      window.location.href = redirectUrl;

      handleClose();
    } catch (err) {
      alert("Error: " + err.graphQLErrors[0].message);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={showModal}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={showModal}>
        <Box sx={style}>
          <div id="transition-modal-title" className="medium-text">
            Create a new Potluck
          </div>
          <div
            className="medsmall-text-"
            id="transition-modal-description"
            style={{ marginTop: "10px" }}
          >
            Potlucks allow you to share recipes within a select group of trusted
            friends
          </div>
          <Box
            component="form"
            noValidate
            onSubmit={handleCreate}
            sx={{ margin: "20px" }}
          >
            <FormLabel>Potluck Name*:</FormLabel>
            <TextField
              margin="normal"
              fullWidth
              size="small"
              id="title"
              placeholder="Family Recipes"
              name="title"
              sx={{ mb: "20px" }}
            />
            <FormLabel>Description (optional):</FormLabel>
            <TextField
              margin="normal"
              fullWidth
              size="small"
              id="description"
              name="description"
            />
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  label="Is this potluck associated with a time and place?"
                  className="small-text"
                  labelPlacement="start"
                  control={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginLeft: "20px",
                      }}
                    >
                      <div className="small-text">No</div>
                      <Switch
                        checked={checked}
                        onChange={(e) => handleCheck(e)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <div className="small-text">Yes</div>
                    </div>
                  }
                />
              </FormGroup>
            </FormControl>
            {checked ? (
              <Box>
                <FormLabel sx={{ display: "flex", m: "10px 0px 15px 0px" }}>Time:</FormLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    onChange={(newValue) => setValue(newValue)}
                  />
                </LocalizationProvider>
                <FormLabel sx={{ display: "flex", m: "10px 0px 0px 0px" }}>Location:</FormLabel>
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  id="place"
                  placeholder=""
                  name="place"
                />
              </Box>
            ) : (
              <></>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ margin: "30px 10px 20px 10px" }}
            >
              Create Potluck
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CreatePotluckModal;
