import React from "react";
import { Box, Card, CardContent, CardActionArea } from "@mui/material";
import { Link } from "react-router-dom";
import ptlkImg from "../../../utils/assets/images/potluck.png";

const PotluckBar = ({ id, title, img, time, place }) => {
  var date = new Date(parseInt(time));
  let newDate = `${date.toString().substring(0, 3)} ${
    date.getMonth() + 1
  }/${date.getDate()}/${date.getFullYear()}`;

  return (
    <div>
      <Card
        sx={{
          height: 65,
          margin: "20px 20px 0px 20px",
          backgroundColor: "#EBECF0",
          borderRadius: "16px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <CardActionArea component={Link} to={`/mypotlucks/${id}`}>
          <CardContent
            sx={{
              marginTop: "-8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img
                alt="Potluck picture"
                src={img || ptlkImg}
                style={{
                  width: 50,
                  height: 50,
                  margin: "0px 20px 0px 10px",
                  borderRadius: "16px",
                }}
              />
              <div className="medsmall-text" style={{ marginLeft: "30px" }}>
                {title}
              </div>
            </Box>
            <Box sx={{ textAlign: "right" }}>
              {time ? (
                <Box>
                  <div className="small-text">{newDate}</div>
                </Box>
              ) : (
                <></>
              )}
              <Box sx={{ mt: "3px" }}>
                <div className="small-text">{place}</div>
              </Box>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
};

export default PotluckBar;
