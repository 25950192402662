import React from "react";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  FormLabel,
  Container,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const Instructions = ({ recipe, method, setMethod }) => {
  // adds an instruction row
  const handleAddInstr = () => {
    const newField = { id: method.length + 1, value: "" };
    setMethod([...method, newField]);
  };

  // removes instruction when bin button clicked
  const handleInstrRemove = (e, id) => {
    e.preventDefault();
    const newArray = method.filter((field) => field.id !== id);

    const updatedArray = newArray.map((item, index) => ({
      ...item,
      id: index + 1,
    }));
    setMethod(updatedArray);
  };

  // changes value of instruction
  const handleInstrChange = (id, value) => {
    const updatedFields = method.map((field) =>
      field.id === id ? { ...field, value } : field
    );
    setMethod(updatedFields);
  };

  // add new box if enter is pressed
  const keyMthdPress = (e) => {
    if (e.keyCode === 13) {
      handleAddInstr();
      e.preventDefault();
    }
  };

  return (
    <Box sx={{ marginTop: "20px", width: "95%" }}>
      <FormLabel>Instructions:</FormLabel>
      {method.map((field, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormLabel>{field.id}.</FormLabel>
          <TextField
            value={field.value}
            size="small"
            onChange={(e) => handleInstrChange(field.id, e.target.value)}
            onKeyDown={(e) => keyMthdPress(e)}
            fullWidth
            multiline
            autoFocus={method.length > 1}
            sx={{ m: "10px" }}
          />
          <IconButton
            onClick={(e) => handleInstrRemove(e, field.id)}
          >
            <DeleteOutlineIcon sx={{ padding: "0px" }} />
          </IconButton>
        </Box>
      ))}
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          paddingLeft: "20px",
        }}
      >
        <IconButton onClick={handleAddInstr}>
          <AddCircleIcon />
        </IconButton>
        <Typography>Add Step</Typography>
      </Container>
    </Box>
  );
};

export default Instructions;
