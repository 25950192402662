import React, { useState } from "react";
import {
  Backdrop,
  Box,
  Modal,
  Fade,
  Button,
  Typography,
  TextField,
  TableContainer,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import { useMutation } from "@apollo/client";
import { ADD_POTLUCK_DETAIL } from "../../../../../utils/mutations";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 750,
  bgcolor: "white",
  borderRadius: "16px",
  boxShadow: 24,
  p: 4,
};

const EditPtlkDetModal = ({ potluckId, potluck, show, setShow }) => {
  const [addPotluckDetail] = useMutation(ADD_POTLUCK_DETAIL);
  const [image, setImage] = useState(null);
  const [value, setValue] = useState(
    potluck && potluck.time ? potluck.time : null
  );

  const handleClose = () => {
    setShow(false);
  };

  const imgChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      // if (file) {
      fileReader.readAsDataURL(file);
      // }
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    var base64 = await convertToBase64(formData.get("img"));

    // Using arbitrary number that is within confidence interval for a non-image upload
    if (base64.length < 100) {
      base64 = null;
    }

    try {
      const { data } = await addPotluckDetail({
        variables: {
          potluckId: potluckId,
          title: formData.get("title"),
          img: base64,
          description: formData.get("description"),
          time: value,
          place: formData.get("place"),
        },
      });
      if (!data) {
        throw new Error("Something went wrong!");
      }
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={show}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={show}>
        <Box sx={style}>
          <TableContainer sx={{ maxHeight: 550 }}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Edit Potluck Detail
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Add additional information about the event that your fellow
              potluck members will see
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={(e) => handleSubmit(e)}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                fullWidth
                id="title"
                label="Title"
                name="title"
                defaultValue={potluck.title}
              />
              <TextField
                margin="normal"
                fullWidth
                id="description"
                label="Description"
                name="description"
                defaultValue={potluck.description}
                sx={{ mb: "20px" }}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  onChange={(newValue) => setValue(newValue)}
                  defaultValue={dayjs(
                    parseInt(potluck && potluck.time ? potluck.time : null)
                  )}
                />
              </LocalizationProvider>
              <TextField
                margin="normal"
                fullWidth
                id="place"
                label="Place"
                name="place"
                defaultValue={potluck.place}
                sx={{ mt: "20px"}}
              />
              <div
                id="transition-modal-description"
                style={{ marginTop: "15px", marginBottom: "15px" }}
              >
                Choose an image from your files (jpeg or png)
              </div>
              <div style={{ marginBottom: "25px" }}>
                <input
                  type="file"
                  label="Image"
                  id="upload-image"
                  name="img"
                  accept=".jpeg, .png"
                  onChange={(e) => imgChange(e)}
                />
              </div>
              <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
                Save to Potluck
              </Button>
            </Box>
          </TableContainer>
        </Box>
      </Fade>
    </Modal>
  );
};

export default EditPtlkDetModal;
