import React from "react";
import { Box, Switch, FormLabel } from "@mui/material";

const PublicPrivate = ({ recipe, checked, setChecked }) => {
  const handleCheck = (e) => {
    e.preventDefault();
    setChecked(!checked);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mt: "30px",
        border: "solid 2px #fa8661",
        borderRadius: "10px",
        padding: "8px"
      }}
    >
      <FormLabel>
        Make recipe public to all friends, or private just for you
      </FormLabel>
      <FormLabel>
        (when private, you can still share within a Potluck!)
      </FormLabel>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Box className="small-text">Public</Box>
        <Switch
          checked={checked}
          onChange={(e) => handleCheck(e)}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Box className="small-text">Private</Box>
      </Box>
    </Box>
  );
};

export default PublicPrivate;
