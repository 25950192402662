import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";

// components
import image from "../../../../utils/assets/images/potluck.png";
import EditIcon from "@mui/icons-material/Edit";
import EditPtlkDetModal from "./components/EditPtlkDetModal";

const TopBar = ({ potluck }) => {
  const [showModal, setShowModal] = useState(false);

  let newDate = null;
  if (potluck && potluck.time) {
  const date = new Date(parseInt(potluck.time));
  newDate = `${date.toString().substring(0, 3)} ${
    date.getMonth() + 1
  }/${date.getDate()}/${date.getFullYear()}`;
}

  const handleEditClick = (event) => {
    event.preventDefault();
    setShowModal(true);
  };
  return (
    <Box
      className="list-box-users"
      sx={{
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <img
          alt="Potluck cover photo"
          src={potluck.img || image}
          style={{
            width: 150,
            height: 130,
            margin: "0px 20px 0px 10px",
            borderRadius: "16px",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box>
          <div className="title">{potluck.title}</div>
          {potluck.description ? (
            <Box className="mt-10">
              <div className="small-text italic">{potluck.description}</div>
            </Box>
          ) : (
            <></>
          )}
          {potluck && potluck.time ? (
            <Box>
              <div className="small-text mt-5">{newDate}</div>
            </Box>
          ) : (
            <></>
          )}
          {potluck.place ? (
            <Box>
              <div className="small-text mt-5">{potluck.place}</div>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "start", margin: "10px" }}>
        <IconButton aria-label="edit" onClick={(e) => handleEditClick(e)}>
          <EditIcon />
        </IconButton>
      </Box>
      <EditPtlkDetModal
        potluckId={potluck._id}
        potluck={potluck}
        show={showModal}
        setShow={setShowModal}
      />
    </Box>
  );
};

export default TopBar;
