import React from "react";
import { Box, TextField, FormLabel } from "@mui/material";

const RecipeName = ({ recipe }) => {
  return (
    <Box sx={{ width: "95%", mb: "15px"}}>
      <FormLabel>Recipe Name:</FormLabel>
      <TextField
        margin="normal"
        id="name"
        placeholder="Chocolate Sauce"
        name="name"
        size="small"
        autoFocus
        fullWidth
        defaultValue={recipe ? recipe.name : ""}
      />
    </Box>
  );
};

export default RecipeName;
