import React from "react";
import { Box, CardActionArea, CardMedia } from "@mui/material";
import { Link } from "react-router-dom";

import brekky from "../../../../utils/assets/images/brekky.png";
import dinner2 from "../../../../utils/assets/images/dinner2.png";
import beverage2 from "../../../../utils/assets/images/beverages2.png";
import dessert3 from "../../../../utils/assets/images/dessert3.png";

const FeedCardRecipe = ({ recipe }) => {
  const autoImgChoose = (type) => {
    if (type === "Beverage") {
      return beverage2;
    } else if (type === "Breakfast" || type === "Brunch") {
      return brekky;
    } else if (type === "Dessert") {
      return dessert3;
    } else if (type === "Dinner" || type === "Lunch") {
      return dinner2;
    } else {
      return dinner2;
    }
  };

  return (
    <CardActionArea
      component={Link}
      to={`/recipe/${recipe._id}`}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "left",
      }}
    >
      <CardMedia
        component="img"
        image={recipe.img || autoImgChoose(recipe.mealType)}
        alt={recipe.name}
        sx={{
          height: { xs: "75px", sm: "150px" },
          width: { xs: "100px", sm: "200px" },
          border: { xs: "10px solid white", sm: "20px solid white" },
        }}
      />
      <Box>
        <Box className="medium-text" sx={{ m: "20px 0px 0px 20px" }}>
          {recipe.name}
        </Box>
        <Box className="small-text" sx={{ m: "20px" }}>
          {recipe.comment}
        </Box>
      </Box>
    </CardActionArea>
  );
};

export default FeedCardRecipe;
