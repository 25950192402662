import React from "react";
import { Typography } from "@mui/material";

export default function LandingPageHeader() {
  return (
    <div className="tmcHeader">
      The
      <span
        style={{ fontStyle: "italic", marginLeft: "15px", marginRight: "15px" }}
      >
        Munch
      </span>
      Collective
    </div>
  );
}
