import React from "react";
import { Card } from "@mui/material";

import FeedCardHeader from "./FeedCardHeader";
import FeedCardRecipe from "./FeedCardRecipe";
import RecipeLike from "../../RecipeLike";

const FeedCard = ({ recipe, me }) => {
  return (
    <Card
      sx={{
        backgroundColor: "#EBECF0",
        borderRadius: "16px",
        margin: "8px 8px 20px 8px",
        padding: "20px",
      }}
    >
      <FeedCardHeader recipe={recipe} />
      <FeedCardRecipe recipe={recipe} />
      <RecipeLike recipe={recipe} me={me} />
    </Card>
  );
};

export default FeedCard;
