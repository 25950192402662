import React from "react";
import { Box, Container, Typography, Skeleton } from "@mui/material";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import KitchenIcon from "@mui/icons-material/Kitchen";
import LockIcon from "@mui/icons-material/Lock";

const Munch = ({ loading = false, user, isFriend }) => {
  return (
    <Container sx={{ mb: "25px" }}>
      <Box className={"box-subheader"}>Munch</Box>
      {loading ? (
        <Box>
          <Skeleton
            height={10}
            width="80%"
            style={{ marginTop: "16px", marginBottom: 6 }}
          />
          <Skeleton height={10} width="70%" style={{ marginBottom: 6 }} />
          <Skeleton height={10} width="60%" />
        </Box>
      ) : (
        <Box>
          {isFriend ? (
            <Box
              sx={{
                fontSize: "18px",
                marginTop: "15px",
              }}
            >
              {!user.favCuisine && !user.signatureDish ? (
                <Box className="small-text">No munch added</Box>
              ) : (
                <Box>
                  {user.favCuisine ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "8px",
                      }}
                    >
                      <KitchenIcon sx={{ marginRight: "8px" }} />
                      <Box className="small-text">
                        Favourite cuisine is {user.favCuisine}
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  )}
                  {user.signatureDish ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "8px",
                      }}
                    >
                      <LocalDiningIcon sx={{ marginRight: "8px" }} />{" "}
                      <Box className="small-text">
                        Signature Dish: {user.signatureDish}
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
              )}
            </Box>
          ) : (
            <LockIcon sx={{ mt: "10px" }} />
          )}
        </Box>
      )}
    </Container>
  );
};

export default Munch;
