import React from "react";
import { Backdrop, Box, Modal, Fade, Typography } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  bgcolor: "white",
  borderRadius: "16px",
  boxShadow: 24,
  p: 4,
};

const ShareRecipeModal = ({ recipeId, setShowShareModal, showShareModal }) => {
  const handleClose = () => setShowShareModal(false);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      open={showShareModal}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={showShareModal}>
        <Box sx={style}>
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            sx={{ marginBottom: "10px" }}
          >
            Copy link and share:
          </Typography>
          <Box>http://www.themunchco.com/recipe/{recipeId}</Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ShareRecipeModal;

