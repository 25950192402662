import React from "react";
import { Skeleton } from "@mui/material";

const LoadingMembers = () => {
  return (
    <Skeleton
      variant="rounded"
      height={300}
      sx={{ borderRadius: "16px", margin: "20px 20px 20px 0px" }}
    />
  );
};

export default LoadingMembers;
