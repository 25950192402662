import React, { useState } from "react";
import { Grid, Box, Container, IconButton } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import RecipeCardLg from "../../../../components/RecipeCardLg";
import LoadingRecipeCard from "../../../../components/RecipeCardLg/LoadingRecipeCard";
import SearchBar from "../../../../components/SearchBar";

const ProfileRecipeList = ({ loading, myRecipes, handlePageChange }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const filterData = (query, data) => {
    if (!query) {
      return data;
    } else {
      return data.filter((q) =>
        q.name.toLowerCase().includes(query.toLowerCase())
      );
    }
  };

  const dataFiltered = filterData(searchQuery, myRecipes);

  return (
    <Box className="basic-box">
      <Container
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
        >
          <Box className="box-subheader" sx={{ marginBottom: "10px" }}>
            Your Recipes
          </Box>
          {loading ? null : (
            <Box
              sx={{
                mt: { xs: "0px", md: "12px" },
                mb: { xs: "10px", md: "0px" },
                ml: { xs: "-15px", md: "0px" },
              }}
            >
              <SearchBar
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
            </Box>
          )}
        </Box>
        {loading ? null : (
          <IconButton
            aria-label="edit"
            onClick={() => handlePageChange("AddRecipe")}
          >
            <AddCircleIcon fontSize="large" />
          </IconButton>
        )}
      </Container>
      <Box>
        {loading ? (
          <Grid
            container
            direction="row"
            color="black"
            sx={{ ml: "10px", pr: "20px", mb: "20px" }}
          >
            <LoadingRecipeCard />
            <LoadingRecipeCard />
          </Grid>
        ) : (
          <Box>
            {myRecipes && myRecipes.length ? (
              <div>
                <Grid
                  container
                  direction="row"
                  color="black"
                  sx={{ ml: "10px", pr: "20px" }}
                >
                  {dataFiltered.map((recipe, index) => (
                    <RecipeCardLg key={index} recipe={recipe} />
                  ))}
                </Grid>
              </div>
            ) : (
              <div
                className="medsmall-text"
                style={{
                  paddingBottom: "30px",
                  margin: "15px 0px 0px 25px",
                }}
              >
                Your Recipe Book is empty
              </div>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ProfileRecipeList;
