import React from "react";
import { Grid, Box, Container } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";

// components
import RecipeCardLg from "../../../components/RecipeCardLg";

const UserRecipes = ({ user, isFriend }) => {
  const recipeList = user.privateRecipes.filter(
    (recipe) => recipe.isPrivate === false
  );

  return (
    <Box className="basic-box">
      <Container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box className="box-subheader" sx={{ marginBottom: "10px" }}>
          Recipes {isFriend ? "(" + recipeList.length + ")" : ""}
        </Box>
        <Box />
      </Container>
      {isFriend ? (
        <Box>
          {recipeList && recipeList.length ? (
            <Grid
              container
              direction="row"
              color="black"
              sx={{ marginLeft: "15px" }}
            >
              {recipeList.map((recipe, index) => (
                <RecipeCardLg key={index} recipe={recipe} />
              ))}
            </Grid>
          ) : (
            <Box
              className="medsmall-text"
              style={{ margin: "15px 0px 0px 30px" }}
            >
              No Recipes added
            </Box>
          )}
        </Box>
      ) : (
        <Box>
          <LockIcon sx={{ margin: "0px 0px 40px 20px" }} />
        </Box>
      )}
    </Box>
  );
};

export default UserRecipes;
