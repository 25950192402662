import React from "react";
import { Box, CardActionArea, Container, Avatar } from "@mui/material";
import { Link } from "react-router-dom";

const FeedCardHeader = ({ recipe }) => {
  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        mb: "10px",
      }}
    >
      <CardActionArea
        component={Link}
        to={`/user/${recipe.createdBy._id}`}
        sx={{
          display: "flex",
          justifyContent: "left",
          width: 45,
        }}
      >
        <Avatar
          alt="Profile picture"
          src={recipe.createdBy.profilePic}
          sx={{ width: 45, height: 45 }}
        >
          <Box sx={{ fontSize: "20px" }}>
            {recipe.createdBy.username.charAt(0)}
          </Box>
        </Avatar>
      </CardActionArea>
      <Box className="small-text" sx={{ fontSize: "20px", marginLeft: "20px" }}>
        {recipe.createdBy.username} added a recipe on {recipe.createdAt}
      </Box>
    </Container>
  );
};

export default FeedCardHeader;
