import React from "react";
import { Box, Card, CardContent, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import Auth from "../../utils/auth";

import { QUERY_GETPRIVATERECIPEBYID } from "../../utils/queries";

// components
import Loading from "../../components/Loading";
import Login from "../../pages/Login";

const bull = (
  <Box
    style={{
      display: "inline-block",
      transform: "scale(0.9)",
      marginRight: "10px",
    }}
  >
    •
  </Box>
);

const Recipe = () => {
  const { recId } = useParams();
  // const location = useLocation();

  const { loading, data } = useQuery(QUERY_GETPRIVATERECIPEBYID, {
    variables: { recipeId: recId },
  });

  // redirect if not logged in
  if (!Auth.loggedIn()) {
    // return <Navigate to="/" state={{ from: location }} replace />;
    // return window.location.replace("/login/:recId")
    return <Login recId={recId} />;
  }

  const recipe = data?.getPrivateRecipeById || [];

  if (loading) {
    return <Loading />;
  }
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      style={{ marginTop: "20px", marginBottom: "30px" }}
    >
      <Card
        className="recipe"
        sx={{ width: { sm: "600px", md: "850px" }, minWidth: "340px" }}
      >
        <CardContent
          sx={{
            marginLeft: { xs: "30px", sm: "50px", md: "100px" },
            marginRight: { xs: "30px", sm: "50px", md: "100px" },
          }}
        >
          <Box
            className="small-text"
            sx={{
              marginTop: "45px",
              color: "gray",
            }}
          >
            {recipe.createdAt}
          </Box>
          <Box
            align="center"
            className="large-text"
            style={{ margin: "25px 15px" }}
          >
            {recipe.name}
          </Box>
          <Box
            style={{ margin: "22px", fontStyle: "italic" }}
            align="center"
            className="medsmall-text"
          >
            {recipe.comment}
          </Box>
          <Box
            sx={{
              mt: "30px",
              textAlign: { xs: "left", sm: "center", md: "center" },
            }}
          >
            <Box
              className="small-text"
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "center",
              }}
            >
              <Box sx={{ margin: { xs: "2px", sm: "0px" } }}>
                Serves: {recipe.servings ? recipe.servings : "-"}
              </Box>
              <Box
                sx={{
                  display: { xs: "none", md: "block" },
                  margin: "0px 10px",
                }}
              >
                |
              </Box>
              <Box sx={{ margin: { xs: "2px", sm: "0px" } }}>
                Prep time: {recipe.prepTime ? recipe.prepTime : "-"} mins
              </Box>
              <Box
                sx={{
                  display: { xs: "none", md: "block" },
                  margin: "0px 10px",
                }}
              >
                |
              </Box>
              <Box sx={{ margin: { xs: "2px", sm: "0px" } }}>
                Cook time: {recipe.cookTime ? recipe.cookTime : "-"} mins
              </Box>
              <Box
                sx={{
                  display: { xs: "none", md: "block" },
                  margin: "0px 10px",
                }}
              >
                |
              </Box>
              <Box sx={{ margin: { xs: "2px", sm: "0px" } }}>
                Total time:{" "}
                {recipe.prepTime + recipe.cookTime === 0
                  ? "-"
                  : recipe.prepTime + recipe.cookTime}{" "}
                mins
              </Box>
            </Box>
          </Box>
          <Box sx={{ marginRight: { xs: "30px", sm: "50px", md: "100px" } }}>
            <Box
              className="medsmall-text bold"
              sx={{
                margin: "30px 0px 10px 0px",
              }}
            >
              Ingredients:
            </Box>
            {recipe.ingredients.map((ingredient, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  marginTop: "8px",
                  fontSize: "18px",
                }}
                className="small-text"
              >
                <Box sx={{ marginRight: "8px" }}>-</Box>
                <Box>{ingredient.toLowerCase()}</Box>
              </div>
            ))}
          </Box>
          <Box>
            <Box
              className="medsmall-text bold"
              sx={{
                margin: "40px 0px 10px 0px",
              }}
            >
              Instructions:
            </Box>
            {recipe.method.map((step, index) => (
              <div
                key={index}
                className="small-text"
                style={{
                  marginTop: "10px",
                  fontSize: "18px",
                  lineHeight: 1.4,
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ marginRight: "10px" }}>{index + 1 + ". "}</div>
                  <div>{step}</div>
                </div>
              </div>
            ))}
          </Box>
          <Box>
            {recipe.tips && recipe.tips.length > 0 ? (
              <div>
                <div
                  className="medsmall-text"
                  style={{
                    fontWeight: "bold",
                    marginTop: "40px",
                    marginBottom: "15px",
                  }}
                >
                  Tips:
                </div>
                {recipe.tips.map((tip, index) => (
                  <div
                    key={index}
                    className="small-text"
                    style={{
                      marginTop: "5px",
                      marginLeft: "20px",
                      fontSize: "18px",
                    }}
                  >
                    {bull}
                    {tip}
                  </div>
                ))}
              </div>
            ) : (
              <>
                <Box sx={{ mb: "40px" }}></Box>
              </>
            )}
          </Box>
          <Box>
            {recipe.source ? (
              <div>
                <div
                  className="small-text"
                  style={{
                    fontWeight: "light",
                    marginTop: "40px",
                    marginBottom: "40px",
                    fontSize: "18px",
                  }}
                >
                  <strong>Source:</strong> {recipe.source}
                </div>
              </div>
            ) : (
              <div style={{ marginBottom: "40px" }}></div>
            )}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Recipe;
