import React, { useState } from "react";
import { Container, Box, Button } from "@mui/material";

import { useMutation } from "@apollo/client";
import { ADD_PRIVATE_RECIPE } from "../../../../utils/mutations";

// components
import RecipeName from "../../../../components/editOrAddRecipe/RecipeName";
import Ingredients from "../../../../components/editOrAddRecipe/Ingredients";
import Instructions from "../../../../components/editOrAddRecipe/Instructions";
import MealType from "../../../../components/editOrAddRecipe/MealType";
import Description from "../../../../components/editOrAddRecipe/Description";
import RecipeImage from "../../../../components/editOrAddRecipe/RecipeImage";
import ServeAndTime from "../../../../components/editOrAddRecipe/ServeAndTime";
import Source from "../../../../components/editOrAddRecipe/Source";
import Tips from "../../../../components/editOrAddRecipe/Tips";
import PublicPrivate from "../../../../components/editOrAddRecipe/PublicPrivate";

export default function AddPrivateRecipe({ handlePageChange }) {
  const [ingredients, setIngredients] = useState([{ id: 1, value: "" }]);
  const [method, setMethod] = useState([{ id: 1, value: "" }]);
  const [tips, setTips] = useState([]);
  const [mType, setMType] = useState("");
  const [checked, setChecked] = useState(false);

  const [addPrivateRecipe] = useMutation(ADD_PRIVATE_RECIPE);

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    var imgUpload = await convertToBase64(formData.get("img"));

    // testing to ensure no empty fields
    if (
      method.some((method) => method.value.trim() === "") ||
      ingredients.some((ingredient) => ingredient.value.trim() === "")
    ) {
      alert("You have empty instruction or ingredient fields!");
      return;
    }

    try {
      // Using arbitrary number that is within confidence interval for a non-image upload
      if (imgUpload.length < 100) {
        imgUpload = null;
      }

      const { data } = await addPrivateRecipe({
        variables: {
          input: {
            name: formData.get("name"),
            ingredients: ingredients.map((field) => field.value),
            method: method.map((field) => field.value),
            mealType: formData.get("mealType"),
            comment: formData.get("comment") || "",
            img: imgUpload,
            source: formData.get("source"),
            tips: tips.map((field) => field.value),
            prepTime: parseInt(formData.get("prepTime")),
            cookTime: parseInt(formData.get("cookTime")),
            servings: parseInt(formData.get("servings")),
          },
        },
      });
      if (!data) {
        throw new Error("Something went wrong!");
      }
      window.location.reload();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box>
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit}
        sx={{ margin: "10px 20px 0px 20px" }}
      >
        <div
          className="large-text"
          style={{
            color: "black",
            marginTop: "40px",
            marginBottom: "20px",
          }}
        >
          Add your recipe!
        </div>
        <RecipeName />
        <Description />
        <MealType mealType={mType} setMealType={setMType} />
        <ServeAndTime />
        <Ingredients
          ingredients={ingredients}
          setIngredients={setIngredients}
        />
        <Instructions method={method} setMethod={setMethod} />
        <RecipeImage />
        <Tips tips={tips} setTips={setTips} />
        <Source />
        <PublicPrivate checked={checked} setChecked={setChecked} />
        <Container sx={{ display: "flex", padding: "0px" }}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ margin: "30px 10px 20px 10px" }}
          >
            Add Recipe
          </Button>
          <Button
            type="cancel"
            fullWidth
            variant="contained"
            onClick={() => handlePageChange("PersonalRecipes")}
            sx={{ margin: "30px 10px 20px 10px", backgroundColor: "red" }}
          >
            Cancel
          </Button>
        </Container>
      </Box>
    </Box>
  );
}
