import React from "react";
import { Grid, Box } from "@mui/material";
import Auth from "../../../utils/auth";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { QUERY_GET_POTLUCK_BY_ID, QUERY_GETME } from "../../../utils/queries";

// components
import Login from "../../Login";
import PotluckMembers from "./PotluckMembers";
import TopBar from "./TopBar";
import PotluckRecipes from "./PotluckRecipes";
import Loading from "../../../components/Loading";
import LoadingComps from "./LoadingComps";

export default function SinglePotluck() {
  const { potluckId } = useParams();
  const { loading: meLoading, data: meData } = useQuery(QUERY_GETME);
  const { loading: potluckLoading, data: potluckData } = useQuery(
    QUERY_GET_POTLUCK_BY_ID,
    {
      variables: { potluckId: potluckId },
    }
  );

  // loading state using skeletons
  if (potluckLoading || meLoading) return <LoadingComps />;

  const potluck = potluckData?.getPotluckById || [];
  const me = meData?.getMe || [];

  // am I the creator?
  const iCreated = potluck.createdBy._id === me._id;

  // redirect if not logged in
  if (!Auth.loggedIn()) {
    return <Login />;
  }

  return (
    <div>
      {iCreated ? (
        <Grid container>
          <Grid item xs={12} md={9}>
            <Box>
              <TopBar potluckLoading={potluckLoading} potluck={potluck} />
            </Box>
            <PotluckRecipes me={me} potluck={potluck} />
          </Grid>
          <Grid item xs={12} md={3}>
            <PotluckMembers me={me} members={potluck.members} />
            <></>
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid item xs={12} md={9}>
            <TopBar potluck={potluck} />
            <PotluckRecipes me={me} potluck={potluck} />
          </Grid>
          <Grid item xs={12} md={3}>
            <PotluckMembers me={me} members={potluck.members} />
            <></>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
