import React, { useState } from "react";
import {
  Backdrop,
  Box,
  Modal,
  Fade,
  Container,
  Button,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import { useMutation } from "@apollo/client";
import { EDIT_PRIVATE_RECIPE } from "../../utils/mutations";
import { QUERY_GETME, QUERY_GET_POTLUCK_BY_ID } from "../../utils/queries";

// components
import RecipeName from "../editOrAddRecipe/RecipeName";
import Description from "../editOrAddRecipe/Description";
import MealType from "../editOrAddRecipe/MealType";
import ServeAndTime from "../editOrAddRecipe/ServeAndTime";
import Ingredients from "../editOrAddRecipe/Ingredients";
import Instructions from "../editOrAddRecipe/Instructions";
import RecipeImage from "../editOrAddRecipe/RecipeImage";
import Tips from "../editOrAddRecipe/Tips";
import Source from "../editOrAddRecipe/Source";
import PublicPrivate from "../editOrAddRecipe/PublicPrivate";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  bgcolor: "white",
  borderRadius: "16px",
  boxShadow: 24,
  p: 4,
  maxHeight: 600,
};

const EditRecipeModal = ({ recipe, showEditModal, setShowEditModal }) => {
  // Prefill the useState variables with recipe values
  let ingArr = [];
  let mthdArr = [];
  let tipsArr = [];
  if (recipe.ingredients) {
    for (let i = 0; i < recipe.ingredients.length; i++) {
      ingArr[i] = { id: i + 1, value: recipe.ingredients[i] };
    }
  }
  if (recipe.method) {
    for (let i = 0; i < recipe.method.length; i++) {
      mthdArr[i] = { id: i + 1, value: recipe.method[i] };
    }
  }
  if (recipe.tips) {
    for (let i = 0; i < recipe.tips.length; i++) {
      tipsArr[i] = { id: i + 1, value: recipe.tips[i] };
    }
  }

  const [ingredients, setIngredients] = useState(ingArr);
  const [method, setMethod] = useState(mthdArr);
  const [tips, setTips] = useState(tipsArr);
  const [mealType, setMealType] = useState(recipe.mealType);
  const [checked, setChecked] = useState(recipe.isPrivate);

  const [editPrivateRecipe] = useMutation(EDIT_PRIVATE_RECIPE, {
    refetchQueries: [
      {
        query: QUERY_GET_POTLUCK_BY_ID,
      }
    ]
  });

  const handleClose = () => setShowEditModal(false);

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleEdit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    var imgUpload = await convertToBase64(formData.get("img"));

    try {
      // Using arbitrary number that is within confidence interval for a non-image upload
      if (imgUpload.length < 100) {
        imgUpload = null;
      }

      const recipeId = recipe._id;
      const { data } = await editPrivateRecipe({
        variables: {
          recipeId: recipeId,
          input: {
            name: formData.get("name"),
            ingredients: ingredients.map((field) => field.value),
            method: method.map((field) => field.value),
            mealType: formData.get("mealType"),
            comment: formData.get("comment") || "",
            img: imgUpload,
            source: formData.get("source"),
            tips: tips.map((field) => field.value),
            prepTime: parseInt(formData.get("prepTime")),
            cookTime: parseInt(formData.get("cookTime")),
            servings: parseInt(formData.get("servings")),
            isPrivate: checked,
          },
        },
      });
      if (!data) {
        throw new Error("Something went wrong!");
      }
      // handlePageChange("PersonalRecipes");
      // window.location.reload();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      open={showEditModal}
      onClose={handleClose}
      onSubmit={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={showEditModal}>
        <Box component="form" noValidate onSubmit={handleEdit} sx={style}>
          <TableContainer sx={{ maxHeight: 600 }}>
            <div className="large-text" style={{ marginBottom: "20px" }}>
              Recipe Editor
            </div>
            <RecipeName recipe={recipe} />
            <Description recipe={recipe} />
            <MealType
              recipe={recipe}
              mealType={mealType}
              setMealType={setMealType}
            />
            <ServeAndTime recipe={recipe} />
            <Ingredients
              recipe={recipe}
              ingredients={ingredients}
              setIngredients={setIngredients}
            />
            <Instructions
              recipe={recipe}
              method={method}
              setMethod={setMethod}
            />
            <RecipeImage recipe={recipe} />
            <Tips recipe={recipe} tips={tips} setTips={setTips} />
            <Source recipe={recipe} />
            <PublicPrivate
              recipe={recipe}
              checked={checked}
              setChecked={setChecked}
            />
            <Container sx={{ display: "flex", padding: "0px" }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ margin: "30px 10px 20px 10px" }}
              >
                Update
              </Button>
            </Container>
          </TableContainer>
        </Box>
      </Fade>
    </Modal>
  );
};

export default EditRecipeModal;
