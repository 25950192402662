import React from "react";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  FormLabel,
  Container,
  Button,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const Tips = ({ recipe, tips, setTips }) => {
  // adds a tip
  const handleAddTip = () => {
    const newField = { id: tips.length + 1, value: "" };
    setTips([...tips, newField]);
  };

  // removes a tip when bin button clicked
  const handleTipRemove = (e, id) => {
    e.preventDefault();
    const newArray = tips.filter((field) => field.id !== id);

    const updatedArray = newArray.map((item, index) => ({
      ...item,
      id: index + 1,
    }));
    setTips(updatedArray);
  };

  // changes value of tip 
  const handleTipChange = (id, value) => {
    const updatedFields = tips.map((field) =>
      field.id === id ? { ...field, value } : field
    );
    setTips(updatedFields);
  };

  // add new box if enter is pressed
  const keyIngPress = (e) => {
    if (e.keyCode === 13) {
      handleAddTip();
      e.preventDefault();
    }
  };

  return (
    <Box sx={{ marginTop: "20px", width: "95%" }}>
      <FormLabel>Tips:</FormLabel>
      {tips.map((field, index) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            key={index}
            value={field.value}
            size="small"
            onChange={(e) => handleTipChange(field.id, e.target.value)}
            onKeyDown={(e) => keyIngPress(e)}
            fullWidth
            autoFocus={tips.length > 0}
            sx={{ m: "10px 0px" }}
          />
          <Button
            onClick={(e) => handleTipRemove(e, field.id)}
            sx={{ ml: "5px" }}
          >
            <DeleteOutlineIcon />
          </Button>
        </Box>
      ))}
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          paddingLeft: "0px",
        }}
      >
        <IconButton onClick={handleAddTip}>
          <AddCircleIcon />
        </IconButton>
        <Typography>Add Tip</Typography>
      </Container>
    </Box>
  );
};

export default Tips;
