import React from "react";
import { Skeleton } from "@mui/material";

const LoadingTopBar = () => {
  return (
    <Skeleton
      variant="rounded"
      height={150}
      sx={{ borderRadius: "16px", margin: "20px" }}
    />
  );
};

export default LoadingTopBar;
