import React from "react";
import { Skeleton } from "@mui/material";

const LoadingRecipes = () => {
  return (
    <Skeleton
      variant="rounded"
      height={400}
      sx={{ borderRadius: "16px", margin: "20px" }}
    />
  );
};

export default LoadingRecipes;
