import React from "react";
import { Grid, Box } from "@mui/material";

import Auth from "../../utils/auth";
import { useQuery } from "@apollo/client";
import { QUERY_GETME } from "../../utils/queries";

// components
import Login from "../Login";
import InfoSection from "./InfoSection";
import ProfileSection from "./ProfileSection";
import MyRecipes from "./MyRecipes";
import ErrorPage from "../../components/Error";
import Loading from "../../components/Loading";
import LoadingProfile from "./LoadingProfile";

export default function MyProfile() {
  const { loading, data } = useQuery(QUERY_GETME);
  const myData = data?.getMe || [];

  if (!Auth.loggedIn()) return window.location.replace("/");

  return (
    <Box>
      {/* {loading ? (
        <LoadingProfile />
      ) : ( */}
      <Box>
        {Auth.loggedIn() ? (
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={4}>
              <Box sx={{ mb: "-8px" }}>
                <ProfileSection loading={loading} myData={myData} />
                <InfoSection loading={loading} myData={myData} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              <MyRecipes loading={loading} myRecipes={myData.privateRecipes} />
            </Grid>
          </Grid>
        ) : (
          <ErrorPage />
        )}
      </Box>
      {/* )} */}
    </Box>
  );
}
