import "./App.css";
import React from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import RouteHandler from "./routes/RouteHandler";

// fonts
import "./utils/fonts/SpecialElite.ttf";


// Creates local and production use cases
let httpLink;
if (process.env.NODE_ENV === "production") {
  httpLink = createHttpLink({
    uri: "/graphql",
  });
} else {
  httpLink = createHttpLink({
    uri: "http://localhost:5174/graphql",
  });
}

// Construct request middleware that will attach the JWT token to every request as an `authorization` header
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("id_token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const App = () => {
  return (
    <ApolloProvider client={client}>
      <RouteHandler />
    </ApolloProvider>
  );
};

export default App;
