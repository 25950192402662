import React, { useState } from "react";
import { Button, Box } from "@mui/material";
import Auth from "../../../utils/auth";

import { useMutation } from "@apollo/client";
import { ADD_PRIVATE_RECIPE } from "../../../utils/mutations";

// components
import LandingPageHeader from "../../../components/LandingPageHeader";
import RecipeName from "../../../components/editOrAddRecipe/RecipeName";
import MealType from "../../../components/editOrAddRecipe/MealType";
import Description from "../../../components/editOrAddRecipe/Description";
import Ingredients from "../../../components/editOrAddRecipe/Ingredients";
import Instructions from "../../../components/editOrAddRecipe/Instructions";
import RecipeImage from "../../../components/editOrAddRecipe/RecipeImage";
import ServeAndTime from "../../../components/editOrAddRecipe/ServeAndTime";
import Source from "../../../components/editOrAddRecipe/Source";
import Tips from "../../../components/editOrAddRecipe/Tips";
import PublicPrivate from "../../../components/editOrAddRecipe/PublicPrivate";

const AddFirstRecipe = () => {
  const [ingredients, setIngredients] = useState([{ id: 1, value: "" }]);
  const [method, setMethod] = useState([{ id: 1, value: "" }]);
  const [tips, setTips] = useState([]);
  const [mType, setMType] = useState("");
  const [checked, setChecked] = useState(false);

  const [addPrivateRecipe] = useMutation(ADD_PRIVATE_RECIPE);

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    var base64 = await convertToBase64(formData.get("img"));

    try {
      // Using arbitrary number that is within confidence interval for a non-image upload
      if (base64.length < 100) {
        base64 = null;
      }

      const { data } = await addPrivateRecipe({
        variables: {
          input: {
            name: formData.get("name"),
            ingredients: ingredients.map((field) => field.value),
            method: method.map((field) => field.value),
            mealType: formData.get("mealType"),
            comment: formData.get("comment") || "",
            img: base64,
            source: formData.get("source"),
            tips: tips.map((field) => field.value),
            prepTime: parseInt(formData.get("prepTime")),
            cookTime: parseInt(formData.get("cookTime")),
            servings: parseInt(formData.get("servings")),
          },
        },
      });
      if (!data) {
        throw new Error("Something went wrong!");
      }
      Auth.signup();
      window.location.replace("/myprofile");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <LandingPageHeader />
      <Box>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{ margin: "10px 20px 20px 20px" }}
        >
          <Box
            sx={{
              display: { sm: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "40px",
              marginBottom: "20px",
              textAlign: "center",
            }}
          >
            <Button sx={{ width: "100px" }} disabled></Button>
            <Box
              className="large-text"
              sx={{
                mt: "25px",
                mb: "15px",
              }}
            >
              Add Your First Recipe!
            </Box>
            <Button
              onClick={() => {
                Auth.signup();
                window.location.replace("/myprofile");
              }}
              sx={{ height: "30px", width: "100px" }}
            >
              Add later
            </Button>
          </Box>
          <RecipeName />
          <Description />
          <MealType mealType={mType} setMealType={setMType} />
          <ServeAndTime />
          <Box
            sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}
          >
            <Ingredients
              ingredients={ingredients}
              setIngredients={setIngredients}
            />
            <Instructions method={method} setMethod={setMethod} />
          </Box>
          <RecipeImage />
          <Tips tips={tips} setTips={setTips} />
          <Source />
          <PublicPrivate checked={checked} setChecked={setChecked} />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ m: "30px 0px" }}
          >
            Add Recipe
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default AddFirstRecipe;
