import React, { useState } from "react";
import {
  Backdrop,
  Box,
  Modal,
  Fade,
  Button,
  Typography,
  Avatar,
  Container,
} from "@mui/material";

import { useMutation } from "@apollo/client";
import { ADD_USER_DETAILS } from "../../../../utils/mutations";
import tempImg from "../../../../utils/assets/images/Home_Image.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 250, sm: 750 },
  bgcolor: "white",
  borderRadius: "16px",
  boxShadow: 24,
  p: 4,
};

const EditProfileModal = ({ set, show }) => {
  const [addUserDetails] = useMutation(ADD_USER_DETAILS);
  const [image, setImage] = useState(null);

  const handleClose = () => {
    set(false);
    window.location.reload();
  };

  const imgChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      // if (file) {
      fileReader.readAsDataURL(file);
      // }
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    var base64 = await convertToBase64(formData.get("img"));

    // Using arbitrary number that is within confidence interval for a non-image upload
    if (base64.length < 100) {
      base64 = null;
    }

    try {
      const { data } = await addUserDetails({
        variables: {
          profilePic: base64,
        },
      });
      if (!data) {
        throw new Error("Something went wrong!");
      }
      handleClose();
    } catch (err) {
      console.error(err);
      alert("An error has been found!");
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={show}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={show}>
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <Box className="medium-text" id="transition-modal-title">
                Add / change your profile picture
              </Box>
              <Box
                className="medsmall-text"
                id="transition-modal-description"
                sx={{ marginTop: "15px", marginBottom: "15px" }}
              >
                Choose an image from your files (jpeg or png)
              </Box>
              <Box>
                <input
                  type="file"
                  label="Image"
                  id="upload-image"
                  name="img"
                  accept=".jpeg, .png"
                  onChange={(e) => imgChange(e)}
                />
              </Box>
              <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
                Save to Profile
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                className="medsmall-text"
                sx={{ textAlign: "center", m: "20px" }}
              >
                Preview:
              </Box>
              <Avatar
                alt="Profile picture"
                src={image}
                sx={{ width: 150, height: 150 }}
              />
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default EditProfileModal;
