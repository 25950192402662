import React, { useState } from "react";
import {
  Box,
  Container,
  Avatar,
  Typography,
  IconButton,
  Tooltip,
  Skeleton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

// components
import EditProfileModal from "./Modal/EditProfileModal.js";

const ProfileSection = ({ loading, myData }) => {
  const [showModal, setShowModal] = useState(false);

  const handleEdit = (event) => {
    event.preventDefault();
    setShowModal(true);
  };

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 150,
      color: "white",
    },
  });

  // Define experties status based on number of recipes added
  var status = "";
  if (myData.privateRecipes && myData.privateRecipes.length === 0) {
    status = "Dish Washer";
  } else if (myData.privateRecipes && myData.privateRecipes.length <= 1) {
    status = "Potato Peeler";
  } else if (myData.privateRecipes && myData.privateRecipes.length <= 5) {
    status = "Sauce Maker";
  } else if (myData.privateRecipes && myData.privateRecipes.length <= 10) {
    status = "Sous Chef";
  } else if (myData.privateRecipes && myData.privateRecipes.length > 10) {
    status = "Head Chef";
  }

  return (
    <Box className="vert-stack-box">
      <Container
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box />
        {loading ? (
          <Box sx={{ height: "55px", marginRight: "15px" }} />
        ) : (
          <IconButton
            aria-label="edit"
            onClick={handleEdit}
            sx={{ marginTop: "15px", marginRight: "15px" }}
          >
            <EditIcon />
          </IconButton>
        )}
      </Container>
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        {loading ? (
          <Skeleton variant="circular" width={175} height={175} />
        ) : (
          <Avatar
            alt="Profile picture"
            src={myData.profilePic}
            sx={{ width: 175, height: 175 }}
          >
            <Typography
              sx={{
                fontSize: "50px",
                fontFamily: "Lotion-BoldWithoutLigatures",
              }}
            >
              {myData.username.charAt(0)}
            </Typography>
          </Avatar>
        )}
      </Container>
      <Container
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {loading ? (
          <Skeleton height={50} width="75%" />
        ) : (
          <div className="username">{myData.username}</div>
        )}
      </Container>
      {loading ? (
        <Container
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <Skeleton height={50} width="75%" />
        </Container>
      ) : (
        <Container
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "40px",
              fontFamily: "Victors",
              mt: "-5px",
            }}
          >
            {status}
          </Typography>
          <CustomWidthTooltip
            arrow
            title={
              <React.Fragment>
                <div
                  className="small-text"
                  style={{
                    color: "white",
                    margin: 8,
                    fontSize: "17px",
                    lineHeight: 1.3,
                  }}
                >
                  Add more recipes to climb through the ranks of the TMC kitchen
                  to the title of <em>Head Chef</em>
                </div>
              </React.Fragment>
            }
            placement="right-start"
          >
            <InfoIcon sx={{ ml: "20px" }} />
          </CustomWidthTooltip>
        </Container>
      )}
      <EditProfileModal show={showModal} set={setShowModal} />
    </Box>
  );
};

export default ProfileSection;
