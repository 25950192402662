import React from "react";
import { Box, TableContainer } from "@mui/material";
import { useQuery } from "@apollo/client";
import { QUERY_GET_ALL_OUTGOING_REQUESTS } from "../../../utils/queries";

// components
import Request from "../FriendRequests/Request";

const PendingRequests = () => {
  const { loading, data } = useQuery(QUERY_GET_ALL_OUTGOING_REQUESTS);

  if (loading) {
    return <div>Loading Son!</div>;
  }

  // Get requests and filter for only those pending
  const requests = data?.getAllMyOutgoingRequests || [];
  const pendingReqs = requests.filter((req) => req.status === "pending");

  console.log(pendingReqs, "These are all the pending requests");

  return (
    <Box className="list-box-users">
      <header className="box-header">Pending</header>
      {/* {loading ? (
        <div>Loading</div>
      ) : ( */}
      <TableContainer sx={{ maxHeight: 395, marginBottom: "10px" }}>
        {pendingReqs.length > 0 ? (
          <Box>
            {pendingReqs.map((request, index) => (
              <Request key={index} loading={loading} request={request} incoming={false} />
            ))}
          </Box>
        ) : (
          <div
            className="small-text"
            style={{ fontSize: "20px", marginLeft: "20px" }}
          >
            No requests to show
          </div>
        )}
      </TableContainer>
      {/* )} */}
    </Box>
  );
};

export default PendingRequests;
