import React, { useState } from "react";
import { Box, Container, Typography, IconButton } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useMutation } from "@apollo/client";
import { APPROVE_FRIEND, DECLINE_FRIEND } from "../../../../utils/mutations";

// components
import UserDisplay from "../../../../components/UserDisplay";

const Request = ({ loading, request, incoming = true }) => {
  // setting initial state
  const [status, setStatus] = useState("");
  console.log(request);
  console.log(loading);

  // database mutations
  const [approveFriend] = useMutation(APPROVE_FRIEND);
  const [sayNoToFriend] = useMutation(DECLINE_FRIEND);

  // logic for approving a friend request
  const handleApprove = async (id) => {
    try {
      const { data } = await approveFriend({
        variables: { friendId: id },
      });
      if (!data) {
        throw new Error("Something went wrong!");
      }
      setStatus("Accepted");
    } catch (err) {
      console.log(err);
    }
  };

  // logic for saying no to a friend request
  const handleDecline = async (id) => {
    try {
      const { data } = await sayNoToFriend({
        variables: { friendId: id },
      });
      if (!data) {
        throw new Error("Something went wrong!");
      }
      setStatus("Declined");
    } catch (err) {
      console.log(err);
    }
  };

  if (loading) {
    return <Box>Loading</Box>;
  }

  return (
    <Box>
      {/* In which direciton in the friend request going? */}
      {incoming ? (
        <Container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <UserDisplay userId={request.fromUserId._id} />
          <Container
            sx={{
              display: "flex",
              justifyContent: "right",
            }}
          >
            {status === "" ? (
              <div>
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    handleApprove(request.fromUserId._id);
                  }}
                >
                  <CheckCircleIcon />
                </IconButton>
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    handleDecline(request.fromUserId._id);
                  }}
                >
                  <CancelIcon />
                </IconButton>
              </div>
            ) : (
              <Typography sx={{ color: "green", fontSize: "14px" }}>
                {status}
              </Typography>
            )}
          </Container>
        </Container>
      ) : (
        <Box>
          <Container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <UserDisplay userId={request.toUserId._id} />
            <Typography sx={{ color: "gray", fontSize: "14px" }}>
              Sent
            </Typography>
          </Container>
        </Box>
      )}
    </Box>
  );
};

export default Request;
