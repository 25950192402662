import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import { useMutation } from "@apollo/client";
import { EDIT_PRIVATE_RECIPE } from "../../utils/mutations";
import { QUERY_GETALLPRIVATERECIPES } from "../../utils/queries";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";

const RecipeLike = ({ recipe, me }) => {
  const [likeCnt, setLikeCnt] = useState(recipe.likes.length);
  const [isLiked, setIsLiked] = useState(
    recipe.likes.some((like) => like === me._id.toString())
  );
  const [editPrivateRecipe] = useMutation(EDIT_PRIVATE_RECIPE, {
    refetchQueries: [
      {
        query: QUERY_GETALLPRIVATERECIPES,
      }
    ]
  });

  const likeBtnClick = async (e) => {
    e.preventDefault();
    setLikeCnt(likeCnt + 1);
    setIsLiked(!isLiked);

    try {
      const { data } = await editPrivateRecipe({
        variables: {
          recipeId: recipe._id,
          input: {
            name: recipe.name,
            ingredients: recipe.ingredients,
            method: recipe.method,
            mealType: recipe.mealType,
            comment: recipe.comment,
            img: recipe.img,
            source: recipe.source,
            tips: recipe.tips,
            prepTime: recipe.prepTime,
            cookTime: recipe.cookTime,
            servings: recipe.servings,
            isPrivate: recipe.isPrivate,
            likes: [...recipe.likes, me._id.toString()],
          },
        },
      });
      if (!data) {
        throw new Error("Something went wrong!");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const unlikeBtnClick = async (e) => {
    e.preventDefault();
    setLikeCnt(likeCnt - 1);
    setIsLiked(!isLiked);

    try {
      const { data } = await editPrivateRecipe({
        variables: {
          recipeId: recipe._id,
          input: {
            name: recipe.name,
            ingredients: recipe.ingredients,
            method: recipe.method,
            mealType: recipe.mealType,
            comment: recipe.comment,
            img: recipe.img,
            source: recipe.source,
            tips: recipe.tips,
            prepTime: recipe.prepTime,
            cookTime: recipe.cookTime,
            servings: recipe.servings,
            isPrivate: recipe.isPrivate,
            likes: recipe.likes.filter((likeId) => likeId !== me._id.toString()),
          },
        },
      });
      if (!data) {
        throw new Error("Something went wrong!");
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "right",
        alignItems: "center",
      }}
    >
      {isLiked ? (
        <IconButton sx={{ color: "red" }} onClick={(e) => unlikeBtnClick(e)}>
          <FavoriteIcon />
        </IconButton>
      ) : (
        <IconButton sx={{ color: "red" }} onClick={(e) => likeBtnClick(e)}>
          <FavoriteBorderIcon />
        </IconButton>
      )}
      <Box className="small-text">{likeCnt}</Box>
    </Box>
  );
};

export default RecipeLike;
