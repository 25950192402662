import React from "react";
import {
  Box,
  FooterContainer,
  Row,
  FooterLink,
  Copyright,
} from "./FooterStyles";

const Footer = () => {
  return (
    <Box>
      <FooterContainer>
        <Row>
          <FooterLink href="mailto:help@themunchco.com">Contact Us</FooterLink>
        </Row>
        <Row>
          <div className="copyright">
            <Copyright>
              Copyright © {new Date().getFullYear()} The Munch Collective. All
              rights reserved.
            </Copyright>
          </div>
        </Row>
      </FooterContainer>
    </Box>
  );
};
export default Footer;
