import React from "react";
import { Box, TableContainer } from "@mui/material";
import { useQuery } from "@apollo/client";
import { QUERY_GET_ALL_INCOMING_REQUESTS } from "../../../utils/queries";

// components
import Request from "./Request";

const FriendRequests = () => {
  const { loading, data } = useQuery(QUERY_GET_ALL_INCOMING_REQUESTS);

  if (loading) {
    return <div>Loading Son!</div>;
  }

  // Get requests and filter for only those pending
  const requests = data?.getAllMyIncomingRequests || [];
  const pendingReqs = requests.filter((req) => req.status === "pending");

  return (
    <Box className="list-box-users">
      <header className="box-header">Friend Requests</header>
        <TableContainer sx={{ maxHeight: 395, marginBottom: "10px" }}>
          {pendingReqs.length > 0 ? (
            <Box>
              {pendingReqs.map((request, index) => (
                <Request key={index} loading={loading} request={request} />
              ))}
            </Box>
          ) : (
            <div
              className="small-text"
              style={{ fontSize: "20px", marginLeft: "20px" }}
            >
              No requests to show
            </div>
          )}
        </TableContainer>
    </Box>
  );
};

export default FriendRequests;
