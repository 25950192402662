import React from "react";
import { Box } from "@mui/material";

// components
import Munch from "./components/Munch";
import Info from "./components/Info";

const UserInfo = ({ user, isFriend }) => {
  return (
    <Box
      className="vert-stack-box"
    >
      {/* <UserExpertise user={user} /> */}
      <Info user={user} isFriend={isFriend} />
      <Munch user={user} isFriend={isFriend} />
    </Box>
  );
}

export default UserInfo;
