import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Container,
  Typography,
  Button,
  CardActionArea,
  CardActions,
  Box,
  Avatar,
  Skeleton,
} from "@mui/material";

// components
import ShareRecipeModal from "../ShareRecipeModal";
import EditRecipeModal from "../EditRecipeModal";
import Loading from "../Loading";
import brekky from "../../utils/assets/images/brekky.png";
import dinner2 from "../../utils/assets/images/dinner2.png";
import beverage2 from "../../utils/assets/images/beverages2.png";
import dessert3 from "../../utils/assets/images/dessert3.png";

const LoadingRecipeCard = () => {
  return (
    <Box>
      <Skeleton
        sx={{ margin: "10px" }}
        variant="rounded"
        width={250}
        height={320}
      />
    </Box>
  );
};

export default LoadingRecipeCard;
