import React from "react";
import {
  Box,
  Container,
  Avatar,
  CardActionArea,
  Skeleton,
} from "@mui/material";
import { Link } from "react-router-dom";

import { useQuery } from "@apollo/client";
import { QUERY_GET_USER_BY_ID } from "../../utils/queries";

// components
import Loading from "../Loading";

export default function UserDisplay({userId, isDisabled}) {
  const { loading, data } = useQuery(QUERY_GET_USER_BY_ID, {
    variables: { userId: userId },
  });
  const userData = data?.getUserById || [];

  // if (loading) {
  //   return <Loading />;
  // }

  return (
    <Box>
      {loading ? (
        <Box>
          <Container
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Skeleton
              variant="circular"
              width={45}
              height={45}
              sx={{ margin: "10px 0px 10px 0px" }}
            />
            <Skeleton
              variant="text"
              width={150}
              sx={{ fontSize: "17px", marginLeft: "20px" }}
            />
          </Container>
        </Box>
      ) : (
        <CardActionArea
          disabled={isDisabled}
          component={Link}
          to={`/user/${userId}`}
        >
          <Container
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Avatar
              alt="Profile picture"
              src={userData.profilePic}
              sx={{ width: 45, height: 45, margin: "10px 0px 10px 0px" }}
            >
              <Box className="medsmall-text" sx={{ marginTop: "4px" }}>
                {userData.username.charAt(0)}
              </Box>
            </Avatar>
            <Box
              className="small-text-reg"
              sx={{ fontSize: "17px", marginLeft: "20px" }}
            >
              {userData.username}
            </Box>
          </Container>
        </CardActionArea>
      )}
    </Box>
  );
}
