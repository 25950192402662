import React from "react";
import { Box, Container, IconButton, Skeleton } from "@mui/material";
import CribIcon from "@mui/icons-material/Crib";
import HouseIcon from "@mui/icons-material/House";
import EditIcon from "@mui/icons-material/Edit";
import Person2Icon from "@mui/icons-material/Person2";

const MyInfo = ({ loading, user, setShowModal }) => {
  const handleEdit = (event) => {
    event.preventDefault();
    setShowModal(true);
  };

  return (
    <Box>
      <Container
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box className={"box-subheader"}>Info</Box>
        {loading ? (
          <Box sx={{ height: "70px" }} />
        ) : (
          <IconButton
            aria-label="edit"
            onClick={handleEdit}
            sx={{ margin: "15px" }}
          >
            <EditIcon />
          </IconButton>
        )}
      </Container>
      {loading ? (
        <Box sx={{ marginLeft: "20px" }}>
          <Skeleton height={10} width="80%" style={{ marginBottom: 6 }} />
          <Skeleton height={10} width="70%" style={{ marginBottom: 6 }} />
          <Skeleton height={10} width="60%" />
        </Box>
      ) : (
        <Container>
          {!user.cityLive && !user.cityBorn && !user.yob && !user.bio ? (
            <Box className="small-text">No info added</Box>
          ) : (
            <Box>
              {user.bio ? (
                <Box>
                  <Box
                    className="small-text"
                    sx={{
                      textAlign: "center",
                      fontStyle: "italic",
                      marginBottom: "10px",
                    }}
                  >
                    {user.bio}
                  </Box>
                </Box>
              ) : (
                <></>
              )}
              {user.cityLive ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <HouseIcon sx={{ marginRight: "8px" }} />
                  <Box className="small-text">Lives in {user.cityLive}</Box>
                </Box>
              ) : (
                <></>
              )}
              {user.cityBorn ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <CribIcon sx={{ marginRight: "8px" }} />
                  <Box className="small-text">Born in {user.cityBorn}</Box>
                </Box>
              ) : (
                <></>
              )}
              {user.yob ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <Person2Icon sx={{ marginRight: "8px" }} />
                  <Box className="small-text">
                    Entered this Earth in {user.yob}
                  </Box>
                </Box>
              ) : (
                <></>
              )}
            </Box>
          )}
        </Container>
      )}
    </Box>
  );
};

export default MyInfo;
