import React, { useState } from "react";
import { Box, Container, Typography } from "@mui/material";
import CribIcon from "@mui/icons-material/Crib";
import HouseIcon from "@mui/icons-material/House";
import LockIcon from "@mui/icons-material/Lock";
import Person2Icon from "@mui/icons-material/Person2";

const Info = ({ user, isFriend }) => {
  return (
    <>
      <Container>
        <div className={"box-subheader"}>Info</div>
        {isFriend ? (
          <Typography
            sx={{
              fontSize: "18px",
              marginTop: "10px",
            }}
          >
            {!user.cityLive && !user.cityBorn && !user.yob && !user.bio ? (
              <div className="small-text">No info added</div>
            ) : (
              <div>
                {user.bio ? (
                  <Box>
                    <div
                      className="small-text"
                      style={{
                        textAlign: "center",
                        fontStyle: "italic",
                        marginBottom: "10px",
                      }}
                    >
                      {user.bio}
                    </div>
                  </Box>
                ) : (
                  <></>
                )}
                {user.cityLive ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "8px",
                    }}
                  >
                    <HouseIcon sx={{ marginRight: "8px" }} />
                    <div className="small-text">Lives in {user.cityLive}</div>
                  </Box>
                ) : (
                  <></>
                )}
                {user.cityBorn ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "8px",
                    }}
                  >
                    <CribIcon sx={{ marginRight: "8px" }} />
                    <div className="small-text">Born in {user.cityBorn}</div>
                  </Box>
                ) : (
                  <></>
                )}
                {user.yob ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "8px",
                    }}
                  >
                    <Person2Icon sx={{ marginRight: "8px" }} />
                    <div className="small-text">
                      Entered this Earth in {user.yob}
                    </div>
                  </Box>
                ) : (
                  <></>
                )}
              </div>
            )}
          </Typography>
        ) : (
          <LockIcon sx={{ mt: "10px" }} />
        )}
      </Container>
    </>
  );
};

export default Info;
