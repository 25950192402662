import React, { useState } from "react";
import {
  Button,
  CssBaseline,
  Link,
  Paper,
  Box,
  Grid,
  Container,
  OutlinedInput,
  InputAdornment,
  IconButton,
  InputLabel,
  FormControl,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import Auth from "../../utils/auth";
import { useMutation } from "@apollo/client";
import { ADD_USER, LOGIN_USER } from "../../utils/mutations";
import image from "../../utils/assets/images/cover.jpg";
import breadImg from "../../utils/assets/images/bread.jpg";

// components
import LandingPageHeader from "../../components/LandingPageHeader";

export default function Signup() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const [addUser] = useMutation(ADD_USER);
  const [login] = useMutation(LOGIN_USER);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfPassword = () =>
    setShowConfPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    if (formData.get("password") !== formData.get("confirmPassword")) {
      alert("The passwords have to match");
      return;
    }

    if (formData.get("password").length < 8) {
      alert("Your password must be at least 8 characters long");
      return;
    }

    try {
      const { data } = await addUser({
        variables: {
          username: formData.get("firstName") + " " + formData.get("lastName"),
          email: formData.get("email"),
          password: formData.get("password"),
        },
      });

      try {
        const { data } = await login({
          variables: {
            email: formData.get("email"),
            password: formData.get("password"),
          },
        });
        if (!data) {
          throw new Error("Something went wrong with login!");
        }
        Auth.login(data.login.token);
        window.location.replace("/signup/add-first-recipe");
      } catch (err) {
        console.log(err);
      }

      if (!data) {
        throw new Error("Something went wrong with signup!");
      }
    } catch (err) {
      console.error(err);
      alert("Something went wrong!");
    }
  };

  const googleAuth = () => {
    window.open(`http://localhost:5174/auth/google/callback`, "_self");
  };

  return (
    <>
      <LandingPageHeader />
      <Container component="main" maxWidth="lg">
        <Box
          sx={{
            marginTop: 8,
            marginBottom: 8,
          }}
        >
          <Grid container>
            <CssBaseline />
            <Grid
              item
              xs={false}
              sm={6}
              md={6}
              sx={{
                backgroundImage: `url(${breadImg})`,
                backgroundRepeat: "no-repeat",
                backgroundColor: (t) =>
                  t.palette.mode === "light"
                    ? t.palette.grey[50]
                    : t.palette.grey[900],
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              component={Paper}
              elevation={6}
              square
            >
              <Box
                sx={{
                  my: 4,
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div className="medium-text">Sign Up</div>
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSubmit}
                  sx={{ mt: 1 }}
                >
                  <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                    <InputLabel required>First Name</InputLabel>
                    <OutlinedInput
                      required
                      fullWidth
                      label="First Name"
                      name="firstName"
                      autoComplete="firstName"
                      id="email"
                      autoFocus
                    />
                  </FormControl>

                  <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                    <InputLabel required>Last Name</InputLabel>
                    <OutlinedInput
                      label="Last Name"
                      required
                      fullWidth
                      id="lastName"
                      name="lastName"
                      autoComplete="lastName"
                    />
                  </FormControl>

                  <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                    <InputLabel required>Email</InputLabel>
                    <OutlinedInput
                      label="Email"
                      required
                      fullWidth
                      id="email"
                      name="email"
                      autoComplete="email"
                    />
                  </FormControl>

                  <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                    <InputLabel required>Password</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      name="password"
                      label="Password"
                      required
                      fullWidth
                    />
                  </FormControl>

                  <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                    <InputLabel required>Confirm Password</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showConfPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showConfPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      name="confirmPassword"
                      label="Confirm Password"
                      required
                      fullWidth
                    />
                  </FormControl>

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Sign Up
                  </Button>
                  <Grid container>
                    <Grid item>
                      <Link href="/" variant="body2">
                        {"Already have an account? Sign In"}
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
          {/* <button onClick={googleAuth}>Log in with Google</button> */}
        </Box>
      </Container>
    </>
  );
}
