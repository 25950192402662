import React from "react";
import { Grid } from "@mui/material";
import { useQuery } from "@apollo/client";
import { QUERY_GETME, QUERY_GET_ALL_INCOMING_REQUESTS } from "../../utils/queries";
import { Navigate, useLocation } from 'react-router-dom';
import Auth from "../../utils/auth";

// components
import CurrentFriends from "./CurrentFriends";
import UserSearch from "./UserSearch";
import FriendRequests from "./FriendRequests";
import PendingRequests from "./PendingRequests";
import Loading from "../../components/Loading";

const Search = () => {
  const { loading: meLoading, data: meData } = useQuery(QUERY_GETME);
  const { loading: reqLoading, data: reqData } = useQuery(QUERY_GET_ALL_INCOMING_REQUESTS)
  const me = meData?.getMe || [];
  const location = useLocation();

  if (!Auth.loggedIn()) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (meLoading) return <Loading />;

  return (
    <Grid container>
      <Grid item xs={4} md={4}>
        <CurrentFriends me={me} />
      </Grid>
      <Grid item xs={6} md={4}>
        <UserSearch me={me} />
      </Grid>
      <Grid item xs={4} md={4}>
        <FriendRequests />
        <PendingRequests />
      </Grid>
    </Grid>
  );
};

export default Search;
