import { gql } from "@apollo/client";

export const LOGIN_USER = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        email
        username
      }
    }
  }
`;

export const RESET_PWD_LINK = gql`
  mutation resetPwdLink($email: String!) {
    resetPwdLink(email: $email) {
      success
      message
    }
  }
`;

export const RESET_PWD = gql`
  mutation resetPwd($userId: String!, $newPwd: String!) {
    resetPwd(userId: $userId, newPwd: $newPwd) {
      success
      message
    }
  }
`;

export const ADD_USER = gql`
  mutation addUser($username: String!, $email: String!, $password: String!) {
    addUser(username: $username, email: $email, password: $password) {
      user {
        username
        email
        password
        _id
      }
    }
  }
`;

export const ADD_USER_DETAILS = gql`
  mutation addUserDetails(
    $bio: String
    $cityBorn: String
    $cityLive: String
    $favCuisine: String
    $signatureDish: String
    $yob: Float
    $profilePic: String
  ) {
    addUserDetails(
      bio: $bio
      cityBorn: $cityBorn
      cityLive: $cityLive
      favCuisine: $favCuisine
      signatureDish: $signatureDish
      yob: $yob
      profilePic: $profilePic
    ) {
      username
      yob
      signatureDish
      favCuisine
      email
      cityLive
      createdAt
      cityBorn
      bio
      _id
      profilePic
    }
  }
`;

export const ADD_PRIVATE_RECIPE = gql`
  mutation addPrivateRecipe($input: recipeInput!) {
    addPrivateRecipe(input: $input) {
      name
      userId
      _id
      comment
      createdAt
      img
      ingredients
      mealType
      method
      source
      tips
    }
  }
`;

export const ADD_RECIPE = gql`
  mutation addRecipe($input: rcpInput!, $ingredients: ingInput) {
    addRecipe(input: $input, ingredients: $ingredients) {
      name
      _id
      description
      createdAt
      img
      ingredients
      mealType
      instructions
      source
      tips
    }
  }
`;

export const EDIT_PRIVATE_RECIPE = gql`
  mutation editPrivateRecipe($recipeId: String!, $input: recipeInput!) {
    editPrivateRecipe(recipeId: $recipeId, input: $input) {
      name
      userId
      _id
      comment
      createdAt
      img
      ingredients
      mealType
      method
      source
      tips
      prepTime
      cookTime
      servings
      isPrivate
      likes
    }
  }
`;

export const CREATE_POTLUCK = gql`
  mutation createPotluck(
    $title: String!
    $description: String
    $time: String
    $place: String
  ) {
    createPotluck(
      title: $title
      description: $description
      time: $time
      place: $place
    ) {
      _id
      createdAt
      title
      createdBy {
        _id
      }
    }
  }
`;

export const ADD_USER_TO_POTLUCK = gql`
  mutation addUserToPotluck($userId: String!, $potluckId: String!) {
    addUserToPotluck(userId: $userId, potluckId: $potluckId) {
      _id
      members
      title
    }
  }
`;

export const ADD_POTLUCK_DETAIL = gql`
  mutation addPotluckDetail(
    $potluckId: String!
    $title: String
    $img: String
    $description: String
    $time: String
    $place: String
  ) {
    addPotluckDetail(
      potluckId: $potluckId
      title: $title
      img: $img
      description: $description
      time: $time
      place: $place
    ) {
      success
      message
    }
  }
`;

export const ADD_FRIEND = gql`
  mutation addFriend($userId: String!) {
    addFriend(toUserId: $userId) {
      message
      success
    }
  }
`;

export const REMOVE_FRIEND = gql`
  mutation removeFriend($friendId: String!) {
    removeFriend(friendId: $friendId) {
      message
      success
    }
  }
`;

export const APPROVE_FRIEND = gql`
  mutation approveFriend($friendId: String!) {
    approveFriend(friendId: $friendId) {
      message
      success
    }
  }
`;

export const DECLINE_FRIEND = gql`
  mutation sayNoToFriend($friendId: String!) {
    sayNoToFriend(friendId: $friendId) {
      message
      success
    }
  }
`;

export const ADD_FRIEND_TO_POTLUCK = gql`
  mutation addFriendToPotluck($potluckId: String!, $friendId: String!) {
    addFriendToPotluck(potluckId: $potluckId, friendId: $friendId) {
      message
      success
    }
  }
`;

export const ADD_RECIPE_TO_POTLUCK = gql`
  mutation addRecipeToPotluck($potluckId: String!, $recId: String!) {
    addRecipeToPotluck(potluckId: $potluckId, recId: $recId) {
      message
      success
    }
  }
`;

export const REMOVE_RECIPE_FROM_POTLUCK = gql`
  mutation removeRecipeFromPotluck($potluckId: String!, $recId: String!) {
    removeRecipeFromPotluck(potluckId: $potluckId, recId: $recId) {
      message
      success
    }
  }
`;
