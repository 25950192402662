import React from "react";
import { Box, FormLabel, FormControl, Select, MenuItem } from "@mui/material";

const MealType = ({ recipe, mealType = "", setMealType }) => {
  const types = [
    "Appetizer",
    "Beverage",
    "Breakfast",
    "Brunch",
    "Dessert",
    "Dinner",
    "Lunch",
    "Side Dish",
    "Snack",
  ];

  const handleChange = (event) => {
    setMealType(event.target.value);
  };

  return (
    <Box
      sx={{
        width: 300,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <FormLabel>Meal Type:</FormLabel>
      <FormControl width="50px" sx={{ margin: "8px" }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          size="small"
          value={mealType}
          onChange={handleChange}
          name="mealType"
          defaultValue={recipe ? recipe.mealType: ""}
        >
          {types.map((type, index) => (
            <MenuItem key={index} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default MealType;
