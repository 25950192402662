import React, { useState } from "react";
import { Box, Container, Avatar, Typography, Button } from "@mui/material";
import { useMutation } from "@apollo/client";
import { REMOVE_FRIEND } from "../../../utils/mutations";
import { ADD_FRIEND } from "../../../utils/mutations";
import { QUERY_GET_FRIEND_REQUEST } from "../../../utils/queries";

const UserImage = ({ user, isFriend, theyReqd, iReqd }) => {
  // used for the add friend or friend status button
  const [addBtn, setAddBtn] = useState(
    theyReqd
      ? {
          text: "✓ Requested",
          isDisabled: true,
        }
      : iReqd
      ? {
          text: "✓ Accept",
          isDisabled: false,
        }
      : isFriend
      ? {
          text: "Friend",
          isDisabled: true,
        }
      : {
          text: "+ Add Friend",
          isDisabled: false,
        }
  );
  const [addFriend] = useMutation(ADD_FRIEND);
  const [removeFriend] = useMutation(REMOVE_FRIEND);

  // Define experties status based on number of recipes added
  var status = "";
  if (user.privateRecipes && user.privateRecipes.length === 0) {
    status = "Dish Washer";
  } else if (user.privateRecipes && user.privateRecipes.length <= 1) {
    status = "Potato Peeler";
  } else if (user.privateRecipes && user.privateRecipes.length <= 5) {
    status = "Sauce Maker";
  } else if (user.privateRecipes && user.privateRecipes.length <= 10) {
    status = "Sous Chef";
  } else if (user.privateRecipes && user.privateRecipes.length > 10) {
    status = "Head Chef";
  }

  const addFriendClick = async (e) => {
    e.preventDefault();
    setAddBtn({
      text: "✓ Requested",
      isDisabled: true,
    });

    try {
      const { data } = await addFriend({
        variables: {
          userId: user._id,
        },
      });

      if (!data) {
        throw new Error("Something went wrong!");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const acceptFriendClick = async (e) => {
    e.preventDefault();
    setAddBtn({
      text: "Friend",
      isDisabled: true,
    });

    try {
      const { data } = await addFriend({
        variables: {
          userId: user._id,
        },
      });

      if (!data) {
        throw new Error("Something went wrong!");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const declineFriendClick = async (e) => {
    e.preventDefault();
    setAddBtn({
      text: "Declined",
      isDisabled: true,
    });
  };

  const unfriend = async (e) => {
    e.preventDefault();

    try {
      const { data } = await removeFriend({
        variables: {
          friendId: user._id,
        },
      });
      if (!data) {
        throw new Error("something went wrong!");
      }
      // setFriend(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box className="vert-stack-box">
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          mt: "55px",
        }}
      >
        <Avatar
          alt="Profile picture"
          src={user.profilePic}
          sx={{ width: 175, height: 175 }}
        >
          <Typography
            sx={{ fontSize: "50px", fontFamily: "Lotion-BoldWithoutLigatures" }}
          >
            {user.username.charAt(0)}
          </Typography>
        </Avatar>
      </Container>
      <Container
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <Box className="username">{user.username}</Box>
      </Container>
      <Container
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "40px",
            fontFamily: "Victors",
            mt: "-5px",
          }}
        >
          {status}
        </Typography>
      </Container>
      <Box sx={{display: "flex", justifyContent: "center"}}>
        {theyReqd ? (
          <Box>
            <Button
              variant="outlined"
              size="small"
              sx={{ mt: 1, border: " solid 1px black", color: "black", mr: 1 }}
              onClick={acceptFriendClick}
            >
              ✓ Accept
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{ mt: 1, border: " solid 1px black", color: "black", ml: 1 }}
              onClick={addFriendClick}
            >
              x Decline
            </Button>
          </Box>
        ) : (
          <Button
            variant="outlined"
            size="small"
            sx={{ mt: 1, border: " solid 1px black", color: "black" }}
            onClick={addFriendClick}
            disabled={addBtn.isDisabled}
          >
            {addBtn.text}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default UserImage;
