import React, { useState, useEffect } from "react";
import {
  Button,
  CssBaseline,
  TextField,
  // Link,
  Paper,
  Box,
  Grid,
  Container,
  OutlinedInput,
  InputAdornment,
  IconButton,
  InputLabel,
  FormControl,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useLocation, useNavigate } from "react-router-dom";

import Auth from "../../utils/auth";
import { useMutation } from "@apollo/client";
import { LOGIN_USER } from "../../utils/mutations";

// import { useDispatch, useSelector } from "react-redux";
// import { Link, useNavigate } from "react-router-dom";
// import { useLoginMutation } from "../../slices/usersApiSlice";
// import { login } from "../../slices/authSlice";

// components
import LandingPageHeader from "../../components/LandingPageHeader";
import image from "../../utils/assets/images/cover.jpg";
import LoginForm from "./LoginForm";

const Login = ({ recId }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [login] = useMutation(LOGIN_USER);

  // const navigate = useNavigate();
  // const location =   useLocation();
  // const { from } = location.state || null;

  // const dispatch = useDispatch();
  // const navigate = useNavigate();

  // const [login, { isLoading }] = useLoginMutation();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    try {
      // const res = await login({ email, password }).unwrap();
      // navigate('/myprofile');

      // Conduct mutation to login user
      const { data } = await login({
        variables: {
          email: formData.get("email"),
          password: formData.get("password"),
        },
      });
      if (!data) {
        throw new Error("something went wrong!");
      }
      Auth.login(data.login.token);
      Auth.signup();
      window.location.replace("/myprofile");
      // from ? navigate(from) : window.location.replace("/myprofile");

      // dispatch(
      //   login({
      //     username: "Test Username",
      //     email: "testEmail@email.com",
      //   })
      // );
    } catch (err) {
      console.error(err);
      alert("Incorrect username or password. Please try again!");
    }
  };

  const googleAuth = () => {
    window.open(`http://localhost:3000/auth/google/callback`, "_self");
  };

  return (
    <Box>
      <LandingPageHeader />
      <Container component="main" maxWidth="lg">
        <Box
          sx={{
            marginTop: 8,
          }}
        >
          <Grid container>
            <CssBaseline />
            <Grid
              item
              xs={false}
              sm={6}
              md={6}
              sx={{
                backgroundImage: `url(${image})`,
                backgroundColor: (t) =>
                  t.palette.mode === "light"
                    ? t.palette.grey[50]
                    : t.palette.grey[900],
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
            <LoginForm recId={recId} />
          </Grid>
        </Box>
        {/* <button onClick={googleAuth}>Log in with Google</button> */}
      </Container>
    </Box>
  );
};

export default Login;
