import React from "react";
import { Box, FormLabel, InputAdornment, OutlinedInput } from "@mui/material";

const ServeAndTime = ({ recipe }) => {
  // Ensures only numbers can be clicked
  const numKeyDown = (e) => {
    if (e.keyCode >= 65 || e.keyCode >= 90) {
      e.preventDefault();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        marginTop: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <FormLabel sx={{ whiteSpace: "nowrap" }}>Prep Time:</FormLabel>
        <OutlinedInput
          size="small"
          id="outlined-adornment-weight"
          endAdornment={<InputAdornment position="end">min</InputAdornment>}
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            "aria-label": "weight",
          }}
          name="prepTime"
          sx={{ margin: "10px" }}
          fullWidth
          onKeyDown={(e) => numKeyDown(e)}
          defaultValue={recipe && recipe.prepTime ? recipe.prepTime : ""}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <FormLabel sx={{ whiteSpace: "nowrap" }}>Cook Time:</FormLabel>
        <OutlinedInput
          size="small"
          id="outlined-adornment-weight"
          endAdornment={<InputAdornment position="end">min</InputAdornment>}
          aria-describedby="outlined-weight-helper-text"
          name="cookTime"
          sx={{ margin: "10px" }}
          fullWidth
          onKeyDown={(e) => numKeyDown(e)}
          defaultValue={recipe && recipe.cookTime ? recipe.cookTime : ""}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <FormLabel sx={{ whiteSpace: "nowrap" }}>Servings</FormLabel>
        <OutlinedInput
          size="small"
          id="outlined-adornment-weight"
          aria-describedby="outlined-weight-helper-text"
          name="servings"
          sx={{ margin: "10px" }}
          fullWidth
          onKeyDown={(e) => numKeyDown(e)}
          defaultValue={recipe && recipe.servings ? recipe.servings : ""}
        />
      </Box>
    </Box>
  );
};

export default ServeAndTime;
