import React from "react";
import { Box, TextField, FormLabel } from "@mui/material";

const Source = ({ recipe }) => {
  return (
    <Box sx={{ marginTop: "20px" }}>
      <FormLabel>Not a family recipe? Add a source:</FormLabel>
      <TextField
        fullWidth
        size="small"
        placeholder="NYT Cooking"
        name="source"
        defaultValue={recipe ? recipe.source : ""}
      />
    </Box>
  );
};

export default Source;
